export const sanitizeParams = (params) => {
  const toSanitize = ["filters", "sortBy"];
  let decodeParams = decodeURIComponent(params);

  toSanitize.forEach((param) => {
    const reg = new RegExp(`${param}=`, "g");
    decodeParams = decodeParams.replace(reg, param);
  });

  return decodeParams;
};

const sortParams = (sort, params) => {
  for (let i = 0; i < Object.entries(sort).length; i++) {
    const [key, value] = Object.entries(sort)[i];
    params.append(key, `[${value[0]}]=${value[1]}`);
  }
};

const filterParams = (filters, params) => {
  for (let i = 0; i < Object.entries(filters).length; i++) {
    const [key, value] = Object.entries(filters)[i];
    if (value !== null && typeof (value) === "object") {
      for (let i1 = 0; i1 < Object.entries(value).length; i1++) {
        const [name, filterBy] = Object.entries(value)[i1];
        if (filterBy) {
          params.append("filters", `[${name}][${key}]=${filterBy}`);
        }
      }
    }
    if (value && typeof (value) === "string") {
      params.append("filters", `[${key}]=${value}`);
    }
  }
};

const includeParams = (includes, params) => params.append("include", includes.join(","));

export const applyIndexFilter = (url, pagination, filters, includes, sort) => {
  const params = new URLSearchParams();
  if (pagination) {
    params.append("limit", pagination.limit);
    params.append("offset", pagination.offset);
  }

  if (sort) {
    sortParams(sort, params);
  }

  if (filters) {
    filterParams(filters, params);
  }

  if (includes) {
    includeParams(includes, params);
  }

  return sanitizeParams(params);
};
