import React from "react";

import TrackingCodes from "./components/trackingCodesComponent";

export default () => (
  <>
    <p className="mt-3 mb-4 pl-4">
      <span className="text-grey-500">Dashboard / Amplify Campaigns</span>
      {" "}
      / Campaign Tracking Codes
    </p>

    <h1 className="mb-4 text-xl font-bold pl-4">Tracking Codes</h1>
    <div className="border-grey-300 border border-solid bg-white p-4">
      <TrackingCodes />
    </div>
  </>
);
