import React from "react";
import { useParams } from "react-router-dom";
import CampaignVendorLink from "./components/campaignVendor";

export default () => {
  const { campaign } = useParams();
  return (
    <div className="w-2/4">
      <p className="mt-3 mb-4">
        <span className="text-grey-500">Dashboard</span>
        {" "}
        / Amplify Campaign Linking
      </p>

      <h1 className="mb-4 text-xl font-bold">Link Campaign to Vendor</h1>
      <CampaignVendorLink id={campaign} />
    </div>
  );
};
