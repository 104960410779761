import { combineReducers } from "redux";
import {
  AUTH,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  FETCHING_CONFIG,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,
} from "./actions";

const initialState = {
  loadingConfig: false,
  config: {},
  loadingConfigError: "",
  authUser: false,
  isLoading: false,
  authError: "",
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AUTH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        authUser: true,
      };
    }
    case AUTH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        authUser: false,
        authError: action.error,
      };
    }
    case FETCHING_CONFIG: {
      return {
        ...state,
        loadingConfig: true,
      };
    }
    case FETCH_CONFIG_SUCCESS: {
      return {
        ...state,
        loadingConfig: false,
        config: action.data,
      };
    }
    case FETCH_CONFIG_FAILURE: {
      return {
        ...state,
        loadingConfig: false,
        loadingConfigError: action.error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ homeReducer });
