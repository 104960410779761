import React from "react";

import { Field } from "redux-form";
import EditorFieldComponent from "./editorFieldComponent";

const EditorField = (props) => {
  const { defaultValue } = props;
  return (
    <Field
      {...props}
      defaultValue={defaultValue}
      component={EditorFieldComponent}
    />
  );
};

export default EditorField;
