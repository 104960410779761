export const getWithoutHeaders = (endpoint) => ({
  url: endpoint,
  withCredentials: true,
});

export const getWithHeaders = (endpoint) => ({
  url: endpoint,
  withCredentials: true,
  data: null,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});

export const postWithHeaders = (endpoint, data) => ({
  url: endpoint,
  method: "POST",
  withCredentials: true,
  data,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});

export const deleteWithHeaders = (endpoint, data) => ({
  url: endpoint,
  method: "DELETE",
  withCredentials: true,
  data,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  },
});
