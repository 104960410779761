import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { connect } from "react-redux";
import isEmptyObject from "@helpers/emptyObject";
import filterVendors from "@helpers/filterVendors";

import Button from "@components/Button";
import ErrorDisplay from "@components/ErrorDisplay";
import { LoadingSpinner, IconTick, IconCross } from "@components/Icons";
import Input from "@components/Input";
import Modal from "@components/Modal";
import SuccessDisplay from "@components/SuccessDisplay";

import vendors from "@fixtures/advertisingVendors/vendors";

import CreateLinkForm from "./createLinkForm";

import {
  fetchCampaigns as fetchCampaignsAction,
  createCampaignVendorLink as createCampaignVendorLinkAction,
  fetchAmplifyCampaigns as fetchAmplifyCampaignsAction,
  deleteCampaignVendorLink as deleteCampaignVendorLinkAction,
} from "../store/actions";

import {
  fetchConfig as fetchConfigAction,
} from "../../Home/store/actions";

const CREATE_CAMPAIGN_TRACKING_CODE = loader("../queries/createCampaignTrackingCode.gql");

export const CampaignVendor = ({
  id,
  campaigns,
  fetchCampaigns,
  fetchAmplifyCampaigns,
  createCampaignVendorLink,
  hasExistingLinks,
  createdCampaignVendorLink,
  amplifyCampaigns,
  error,
  config,
  fetchConfig,
  isLoading,
  deleteCampaignVendorLink,
  deletedSuccess,
}) => {
  const [searchValue, setSearchValue] = useState(id);
  const [customTrackingCode, setCustomTrackingCode] = useState();
  const [hasBeenCalled, setHadBeenCalled] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [trackingUrl, setTrackingUrl] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [createCampaignTrackingCode] = useMutation(CREATE_CAMPAIGN_TRACKING_CODE, {
    onCompleted(data) {
      setTrackingUrl(data?.createCampaignTrackingCode.trackingCode.url);
    },
    onError(linkingError) {
      setErrorMessage(linkingError);
    },
  });

  const handleCustomTrackingCode = (e) => {
    setCustomTrackingCode(e.target.value);
  };

  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };

  const searchByCampaignId = (e) => {
    e.preventDefault();
    if (searchValue) {
      fetchAmplifyCampaigns(searchValue);
    }
  };

  const resetInputField = () => {
    setSearchValue("");
    setCustomTrackingCode("");
    setErrorMessage("");
    setTrackingUrl("");
  };

  const callCampaignVendorLink = (e) => {
    e.preventDefault();
    createCampaignVendorLink(searchValue);
    resetInputField();
  };

  useEffect(() => {
    if (config && isEmptyObject(config)) {
      fetchConfig();
    }
  }, [fetchConfig, config]);

  useEffect(() => {
    if (campaigns && isEmptyObject(campaigns) && !hasBeenCalled) {
      fetchCampaigns();
      setHadBeenCalled(true);
    }
  }, [fetchCampaigns, campaigns, hasBeenCalled]);

  useEffect(() => {
    if (id) {
      fetchAmplifyCampaigns(id);
    }
  }, [id, fetchAmplifyCampaigns]);

  const confirmDeletion = (linkId) => {
    setModalVisibility(true);
    setSelectedId(linkId);
  };

  const closeModal = () => {
    setModalVisibility(false);
  };

  const deleteVendorLink = () => {
    deleteCampaignVendorLink(selectedId);
    closeModal();
  };

  const handleCreateTrackingUrl = () => {
    createCampaignTrackingCode(
      {
        variables: {
          input: {
            id: searchValue,
            code: customTrackingCode || "",
          },
        },
      },
    );
  };

  return (
    <div data-testid="campaign-vendor">
      <form className="search-campaign flex mb-4">
        <Input
          value={searchValue}
          onChange={handleSearchInputChanges}
          type="text"
          dataTestId="campaign-vendor-search-id"
          placeholder="Campaign ID"
        />
        <Button
          dataTestId="campaign-vendor-search-id-button"
          onClick={(e) => searchByCampaignId(e)}
          type="submit"
          value="search-by-id"
        >
          Search By Campaign ID
        </Button>
      </form>

      {
        !isEmptyObject(amplifyCampaigns)
        && (
        <div>
          {
            isLoading
              ? (
                <div className="flex flex-center w-full justify-center">
                  <LoadingSpinner />
                </div>
              )
              : (
                <div>
                  {
                !isEmptyObject(amplifyCampaigns)
                && (
                <div>
                  <div className="mb-2 bg-white px-8 py-6 space-y-5  w-full border-solid border border-grey-300 rounded">
                    <div className="mb-2">
                      <p className="font-semibold mb-1">Campaign Owner Name:</p>
                      <p>{ amplifyCampaigns.user_name }</p>
                    </div>
                    <div>
                      <p className="font-semibold mb-1">Campaign Title:</p>
                      <p>{ amplifyCampaigns.title }</p>
                    </div>
                  </div>
                  <div className="mb-2 bg-white px-8 py-6 space-y-5  w-full border-solid border border-grey-300 rounded">
                    <h3 className="font-semibold mb-1">Existing Links: </h3>
                    <ul>
                      {
                        (!hasExistingLinks || hasExistingLinks.length === 0)
                        && <li className="mb-1">No Existing Links</li>
                      }
                      {
                        hasExistingLinks
                        && hasExistingLinks.length > 0
                        && hasExistingLinks.map((i) => (!isEmptyObject(i)
                          ? (
                            <li className="mb-1">
                              <span>
                                { filterVendors(i.advertising_vendor_id) }
                                :
                                {" "}
                                { i.vendor_reference }
                              </span>
                              <Button classNames="link" onClick={() => confirmDeletion(i.id)}><IconCross height="18px" width="18px" /></Button>
                            </li>
                          )
                          : <li className="mb-1">No Existing Links</li>))
                      }
                    </ul>
                  </div>
                </div>
                )
              }

                  {
                    deletedSuccess && <SuccessDisplay message="Successfully deleted vendor link" />
                  }

                  {
                !createdCampaignVendorLink
                  ? <CreateLinkForm vendors={vendors} handleSubmit={callCampaignVendorLink} />
                  : (
                    <div className="campaign-shell-success mt-4 max-w-lg py-2 bg-green-50 border border-solid border-green-100 rounded">
                      <p className="flex items-center">
                        <span className="px-2"><IconTick /></span>
                        Successfully linked campaign with vendor. Input another campaign ID to
                        connect a new campaign and vendor, or the same campaign ID to connect
                        a different vendor reference.
                      </p>
                    </div>
                  )
              }

                  {
                    <Modal
                      isOpen={modalVisibility}
                      contentLabel="Campaign Vendor Delete Modal"
                      ariaHideApp={false}
                    >
                      <div className="flex flex-col items-center text-left">
                        <h3 className="mb-4 font-semibold">Confirm delete for vendor linking:</h3>

                        <div className="flex justify-center">
                          <span className="pr-2">
                            <Button classNames="tertiary" onClick={() => closeModal()}>Cancel</Button>
                          </span>
                          <span>
                            <Button onClick={() => deleteVendorLink()}>Continue</Button>
                          </span>
                        </div>
                      </div>
                    </Modal>
                  }

                  {
                    <div className="mb-4 flex">
                      <Input
                        value={customTrackingCode}
                        onChange={handleCustomTrackingCode}
                        type="text"
                        dataTestId="campaign-vendor-tracking-code"
                        placeholder="Tracking Code (Optional)"
                      />
                      <Button
                        onClick={() => handleCreateTrackingUrl()}
                      >
                        Create Campaign Tracking URL
                      </Button>
                    </div>
                  }
                </div>
              )
          }
        </div>
        )
      }

      {
        trackingUrl && <SuccessDisplay message={`Successfully created tracking code: ${trackingUrl}`} />
      }

      {
        (error || errorMessage)
          && <ErrorDisplay error={error || errorMessage} />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaigns: state.vendorLinking.campaigns,
  amplifyCampaigns: state.vendorLinking.amplifyCampaigns,
  hasExistingLinks: state.vendorLinking.hasExistingLinks,
  deletedSuccess: state.vendorLinking.deletedSuccess,
  createdCampaignVendorLink: state.vendorLinking.createdCampaignVendorLink,
  isLoading: state.vendorLinking.isLoading,
  error: state.vendorLinking.error,
  config: state.dashboard.homeReducer.config,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCampaigns: () => dispatch(fetchCampaignsAction()),
  fetchAmplifyCampaigns: (campaignId) => dispatch(fetchAmplifyCampaignsAction(campaignId)),
  createCampaignVendorLink: (campaignId) => dispatch(createCampaignVendorLinkAction(campaignId)),
  fetchConfig: () => dispatch(fetchConfigAction()),
  deleteCampaignVendorLink: (referenceId) => dispatch(deleteCampaignVendorLinkAction(referenceId)),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const CampaignVendorComponent = connectToStore(CampaignVendor);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignVendorComponent);
