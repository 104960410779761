/* eslint-disable jsx-a11y/anchor-is-valid */
import { React } from "react";
import { Link } from "react-router-dom";
import Button from "@components/Button";
import { isExternalLink } from "@helpers/normalizeData";

const shouldDisableLink = (url, disabledStatus) => disabledStatus || url === "";

const generateLinkType = (url, label, itemInfo) => ((isExternalLink(url) || url === "")
  ? (
    <div className="block px-4 py-1 text-sm">
      <Button disabled={shouldDisableLink(url, itemInfo.disabled)} classNames="link" onClick={() => window.open(url)}>
        { label }
      </Button>
    </div>
  )
  : (
    <Link
      key={itemInfo.key}
      className={`block px-4 py-1 text-sm ${itemInfo.disabled && "opacity-50 cursor-default"}`}
      to={(!itemInfo.disabled && url) || "#"}
    >
      { label }
    </Link>
  ));
export default generateLinkType;
