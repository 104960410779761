// @todo: should be retrieved from an endpoint
export const countries = [
  {
    id: 1,
    label: "Australia",
    name: "AU",
  },
  {
    id: 2,
    label: "Canada",
    name: "CA",
  },
  {
    id: 3,
    label: "United Kingdom",
    name: "GB",
  },
  {
    id: 4,
    label: "United States",
    name: "US",
  },
];

export const vendors = [
  {
    id: 1,
    name: "facebook",
    label: "Facebook Advertising",
  },
];

export const campaignCreation = [
  {
    id: 1,
    label: "One audience per ad set",
    name: "one_per",
  },
  {
    id: 2,
    label: "Multiple audiences in one ad set",
    name: "multiple",
  },
];

export const adAccounts = [
  {
    id: 1,
    name: "Chuffed - Australia - ($AUD)",
  },
  {
    id: 2,
    name: "Chuffed.org EU Ltd - (£GBP)",
  },
  {
    id: 3,
    name: "Chuffed Pty Ltd - ($AUD)",
  },
];

export const audiences = [
  {
    id: 1,
    key: "Selection 1",
    label: "Selection 1",
    donor_criteria: {
      criteria: "campaign.category_id=1",
      version: 1,
    },
  },
  {
    id: 2,
    key: "Selection 2",
    label: "Selection 2",
    donor_criteria: {
      criteria: "campaign.category_id=2",
      version: 1,
    },
  },
];
