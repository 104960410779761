import React from "react";

import AdminFurtherActionsComponent from "@containers/AdminActions";

const AdminActions = () => (
  <>
    <p className="mt-3 mb-4 pl-4">
      <span className="text-grey-500">Dashboard</span>
      {" "}
      / Admin Actions
    </p>
    <h1 className="mb-4 text-xl font-bold pl-4">Admin Actions</h1>
    <div className="p-4">
      <AdminFurtherActionsComponent />
    </div>
  </>
);
export default AdminActions;
