import { fetch as router } from "@helpers/router";
import axios from "axios";

export const CAMPAIGN_APPROVALS_LIST = "dashboard/CAMPAIGN_APPROVALS_LIST";
export function campaignApprovalsList() {
  return {
    type: CAMPAIGN_APPROVALS_LIST,
  };
}

export const CAMPAIGN_APPROVALS_LIST_SUCCESS = "dashboard/CAMPAIGN_APPROVALS_LIST_SUCCESS";
export function campaignApprovalsListSuccess(data) {
  return {
    type: CAMPAIGN_APPROVALS_LIST_SUCCESS,
    data,
  };
}

export const CAMPAIGN_APPROVALS_LIST_FAILURE = "dashboard/CAMPAIGN_APPROVALS_LIST_FAILURE";
export function campaignApprovalsListFailure(error) {
  return {
    type: CAMPAIGN_APPROVALS_LIST_FAILURE,
    error,
  };
}

export const campaignApprovals = () => (dispatch) => {
  const endpoint = router("web.admin.campaigns.index");

  dispatch(campaignApprovalsList());

  const options = {
    url: endpoint,
    withCredentials: true,
    data: null,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    },
  };

  axios(options)
    .catch((error) => dispatch(campaignApprovalsListFailure(error.response.statusText)))
    .then((response) => {
      if (response.error) {
        dispatch(campaignApprovalsListFailure(response.error));
        return;
      }
      dispatch(campaignApprovalsListSuccess(response.data));
    });
};
