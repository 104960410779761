import { roundUp } from "@helpers/numberHelpers";

export const updateDashboardLimit = (
  limit,
  offset,
  type,
  pageRows,
) => {
  const pagination = {
    limit,
    offset,
  };

  switch (type) {
    case "previous": {
      pagination.limit = limit > 10 ? limit - pageRows : limit;
      pagination.offset = offset > 0 ? offset - pageRows : 0;
    }
      break;
    case "next": {
      pagination.limit = pageRows !== 10 ? limit + pageRows : limit;
      pagination.offset = offset + pageRows;
    }
      break;
    default:
      return pagination;
  }

  return pagination;
};

export const detectSelectionOfPreviousNext = (
  offset,
  totalResults,
  pageRows,
) => ({
  previous: offset > 0,
  next: !(offset + pageRows >= totalResults) || (offset + pageRows <= totalResults),
});

export const calculatePageOptionsSize = (total, pageRows) => Math.ceil(roundUp(total) / pageRows);
