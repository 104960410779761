/* eslint-disable react/no-array-index-key */
import React from "react";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import Button from "@components/Button";

const dateColumns = ["deleted_at", "created_at", "updated_at", "start_date"];

const TablePage = () => {
  const { state: oldState } = useLocation();
  const state = oldState.state;
  const page = state && state.pageType;
  const info = state && state[page] && Object.entries(state[page]);

  return (
    <div className="mt-3 mb-4 ml-4" data-testid="user">
      <h1 className="mb-4 text-xl font-bold">
        { page }
        {" "}
        ID:
        {" "}
        { state && state.id }
      </h1>

      {
        state
        && state[page]
        && info.map((detail, i) => (
          <p key={i} className="mb-2" data-testid={detail[0]}>
            <span className="font-medium mr-2">
              {detail[0]}
              :
            </span>
            {
              dateColumns.includes(detail[0])
                ? moment(detail[1]).utc().format("YYYY-MM-DD")
                : (detail[1] || "False")
            }
          </p>
        ))
      }

      {
        state.returnUrl
        && (
        <div className="w-40 mt-4" data-testid={`back-to-${page}`}>
          <Button>
            <Link
              key={state && state.id}
              to={state.returnUrl}
            >
              Back
            </Link>
          </Button>
        </div>
        )
      }
    </div>
  );
};
export default TablePage;
