const amplifyEmails = [
  {
    key: "initial",
    name: "amplify:initial-email",
  },
  {
    key: "not eligible",
    name: "amplify:not-eligible-email",
  },
  {
    key: "follow up",
    name: "amplify:follow-up-email",
  },
];

export default amplifyEmails;
