import React from "react";
import { reduxForm, Field } from "redux-form";
import emailFormFields from "./emailFormFields";

const EmailComponentForm = ({
  defaultContent: {
    subject,
    text,
  },
  user: {
    email,
  },
  enquiryId,
}) => (
  <form className="flex text-left flex-col">
    <h2 className="mb-4 text-xl font-bold">Send email:</h2>
    {
        emailFormFields(email, subject, enquiryId, text).map((data) => (
          <div className="mb-4">
            <Field
              name={data.name}
              type={data.type}
              component={data.component}
              key={data.key}
              id={data.id}
              label={data.label}
              disabled={data.disabled}
              value={data.value}
              defaultValue={data.defaultValue}
            />
          </div>
        ))
      }
  </form>
);

export default reduxForm({
  form: "emailForm",
})(EmailComponentForm);
