import { combineReducers } from "redux";
import {
  RETRIEVE_CAMPAIGNS,
  RETRIEVE_CAMPAIGNS_SUCCESS,
  RETRIEVE_CAMPAIGNS_FAILURE,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  FETCH_STATUSES,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_FAILURE,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
} from "./actions";

const initialState = {
  campaigns: [],
  attemptedCampaignFetch: false,
  isLoading: false,
  sendingEmail: false,
  emailSent: false,
  error: "",
  statuses: {},
  campaignsMeta: {},
};

const amplifyDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_CAMPAIGNS: {
      return {
        ...state,
        isLoading: true,
        attemptedCampaignFetch: false,
      };
    }
    case RETRIEVE_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        campaigns: action.data,
        attemptedCampaignFetch: true,
        campaignsMeta: action.meta,
      };
    }
    case RETRIEVE_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        attemptedCampaignFetch: true,
      };
    }
    case SEND_EMAIL: {
      return {
        ...state,
        sendingEmail: true,
        error: "",
        emailSent: "",
      };
    }
    case SEND_EMAIL_SUCCESS: {
      return {
        ...state,
        sendingEmail: false,
        emailSent: true,
      };
    }
    case SEND_EMAIL_FAILURE: {
      return {
        ...state,
        sendingEmail: false,
        error: action.error,
        emailSent: false,
      };
    }
    case FETCH_STATUSES: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case FETCH_STATUSES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        statuses: action.data,
      };
    }
    case FETCH_STATUSES_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    case UPDATE_STATUS: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_STATUS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ amplifyDashboardReducer });
