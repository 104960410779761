import React from "react";
import { reduxForm, Field } from "redux-form";

import Button from "@components/Button";
import DatePickerDefault from "@components/DatePicker";

import FormField from "@components/FormField";

const validate = (values) => {
  const errors = {};

  if (!values.startDate) {
    errors.startDate = "Required";
  }

  if (!values.endDate) {
    errors.endDate = "Required";
  }

  if (!values.amount) {
    errors.amount = "Required";
  } else if (Number.isNaN(Number(values.amount))) {
    errors.amount = "Must be a number";
  }

  if (!values.description) {
    errors.description = "Required";
  }

  return errors;
};

const CreateInvoice = ({
  handleSubmit,
  submitting,
  invalid,
}) => (
  <form onSubmit={handleSubmit} className="w-2/4" data-testid="create-invoice-form">
    <div className="mb-4" data-testid="campaign-invoice-amount">
      <Field
        name="amount"
        type="number"
        component={FormField}
        label="Amount"
      />
    </div>
    <div className="mb-4" data-testid="campaign-invoice-description">
      <Field
        name="description"
        type="text"
        component={FormField}
        label="Description"
      />
    </div>
    <div className="mb-4 w-60 flex flex-col" data-testid="date-picker">
      <span className="mb-4">
        <p className="mb-1">Start Date:</p>
        <Field
          name="dateStart"
          component={DatePickerDefault}
          placeholder="yyyy-mm-dd"
        />
      </span>
      <span>
        <p className="mb-1">End Date:</p>
        <Field
          name="dateEnd"
          component={DatePickerDefault}
          placeholder="yyyy-mm-dd"
        />
      </span>
    </div>
    <div>
      <Button
        type="submit"
        disabled={invalid || submitting}
        dataTestId="create-invoice-submit"
      >
        Submit
      </Button>
    </div>
  </form>
);

export default reduxForm({
  form: "invoiceForm",
  validate,
})(CreateInvoice);
