import React from "react";
import { IconCross } from "@components/Icons";

const ErrorDisplay = ({
  error,
}) => (
  <div className="mt-4 py-2 pr-2 bg-red-0 border border-solid border-red-200 rounded">
    <p className="flex items-center">
      <span className="px-2"><IconCross /></span>
      { error }
    </p>
  </div>
);

export default ErrorDisplay;
