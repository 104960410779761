import { combineReducers } from "redux";
import {
  RETRIEVE_CAMPAIGN_INVOICE,
  RETRIEVE_CAMPAIGN_INVOICE_SUCCESS,
  RETRIEVE_CAMPAIGN_INVOICE_FAILURE,
  CREATE_CAMPAIGN_INVOICE,
  CREATE_CAMPAIGN_INVOICE_SUCCESS,
  CREATE_CAMPAIGN_INVOICE_FAILURE,
} from "./actions";

const initialState = {
  invoice: {},
  isLoading: false,
  error: false,
  createdInvoice: false,
};

const campaignInvoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_CAMPAIGN_INVOICE: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case RETRIEVE_CAMPAIGN_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invoice: action.data,
        error: "",
      };
    }
    case RETRIEVE_CAMPAIGN_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case CREATE_CAMPAIGN_INVOICE: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case CREATE_CAMPAIGN_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        createdInvoice: true,
        error: "",
      };
    }
    case CREATE_CAMPAIGN_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        createdInvoice: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ campaignInvoicesReducer });
