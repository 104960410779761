import React from "react";
import Home from "@pages/Home";

import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import client from "@helpers/apolloClientLink";
import store from "./globalStore";

const App = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Home />
    </Provider>
  </ApolloProvider>
);

export default App;
