import { fetch as router } from "@helpers/router";
import axios from "axios";

import { getWithHeaders, postWithHeaders } from "@helpers/axiosHelpers";

export const RETRIEVE_USERS = "dashboard/RETRIEVE_USERS";
export function retrieveUsers() {
  return {
    type: RETRIEVE_USERS,
  };
}

export const RETRIEVE_USERS_SUCCESS = "dashboard/RETRIEVE_USERS_SUCCESS";
export function retrieveUsersSuccess(data) {
  return {
    type: RETRIEVE_USERS_SUCCESS,
    data,
  };
}

export const RETRIEVE_USERS_FAILURE = "dashboard/RETRIEVE_USERS_FAILURE";
export function retrieveUsersFailure(error) {
  return {
    type: RETRIEVE_USERS_FAILURE,
    error,
  };
}

export const DELETE_USER_CALL = "dashboard/DELETE_USER_CALL";
export function deleteUserCall() {
  return {
    type: DELETE_USER_CALL,
  };
}

export const DELETE_USER_SUCCESS = "dashboard/DELETE_USER_SUCCESS";
export function deleteUserSuccess(userId) {
  return {
    type: DELETE_USER_SUCCESS,
    userId,
  };
}

export const DELETE_USER_FAILURE = "dashboard/DELETE_USER_FAILURE";
export function deleteUserFailure(error) {
  return {
    type: DELETE_USER_FAILURE,
    error,
  };
}

export const LOGIN_AS_CALL = "dashboard/LOGIN_AS_CALL";
export function loginAsCall() {
  return {
    type: LOGIN_AS_CALL,
  };
}

export const LOGIN_AS_SUCCESS = "dashboard/LOGIN_AS_SUCCESS";
export function loginAsSuccess(data) {
  return {
    type: LOGIN_AS_SUCCESS,
    data,
  };
}

export const LOGIN_AS_FAILURE = "dashboard/LOGIN_AS_FAILURE";
export function loginAsFailure(error) {
  return {
    type: LOGIN_AS_FAILURE,
    error,
  };
}

export const fetchUsers = (userId = null, email = null) => (dispatch) => {
  const defaultEndpoint = router("web.admin.users.index");

  const altEndpoint = (email !== null ? `${defaultEndpoint}?email=${email}` : defaultEndpoint);
  const endpoint = userId !== null ? `${defaultEndpoint}?id=${userId}` : altEndpoint;

  dispatch(retrieveUsers());

  axios(getWithHeaders(endpoint))
    .catch(() => dispatch(retrieveUsersFailure("Unable to retrieve users")))
    .then((response) => {
      // todo: will need to revisit this when we update web endpoints
      const filterDeletedUsers = response && response.data && response.data.filter((i) => i.full_name !== "Deleted User");
      dispatch(retrieveUsersSuccess(filterDeletedUsers));
    });
};

export const deleteUser = (userId) => (dispatch) => {
  const endpoint = router("web.admin.users.destroy", { id: userId });

  dispatch(deleteUserCall());

  const data = null;

  axios(postWithHeaders(endpoint, data))
    .catch(() => dispatch(deleteUserFailure("Unable to delete user")))
    .then(() => dispatch(deleteUserSuccess("Successfully deleted user")));
};

export const loginAs = (userId) => (dispatch) => {
  const endpoint = `${process.env.REACT_APP_URL}/dashboard/admin/users/${userId}/login`;

  dispatch(loginAsCall());

  axios(postWithHeaders(endpoint))
    .then(() => dispatch(loginAsSuccess(userId)))
    .catch(() => dispatch(loginAsFailure("Unable to login as user")));
};
