import routes from "./routes.json";

const replaceParam = (uri, paramName, param) => uri.replace(paramName, param);

export const uriParams = (uri, params) => {
  let newUri = uri;
  const regex = /\{([a-zA-Z_]+)(\??)\}/g;
  const urlExpectedParams = uri.match(regex);

  if (urlExpectedParams !== null && urlExpectedParams !== undefined) {
    if (!params) {
      throw Error(`Please provide param(s): ${urlExpectedParams}`);
    }

    urlExpectedParams.forEach((paramName) => {
      const isOptional = paramName.indexOf("?") > 0;
      const filteredName = paramName.slice(1, isOptional ? -2 : -1);

      if (params[filteredName] === undefined && !isOptional) {
        throw Error(`Missing param: ${filteredName}`);
      }
      newUri = replaceParam(newUri, paramName, params[filteredName] ?? "");
    });
  }

  return newUri;
};

export const fetch = (name, params) => {
  if (routes.hasOwnProperty(name)) {
    return uriParams(`${process.env.REACT_APP_URL}/${routes[name].uri}`, params);
  }
  throw Error(`Unknown route ${name}`);
};

export const fetchAbsolute = (name, params) => {
  if (params) {
    return fetch(name, params);
  }

  return fetch(name);
};
