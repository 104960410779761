import { React } from "react";

const generateCampaignReviewEmailContent = (reasonRef, type) => (type === "Reject"
  ? (
    <div>
      <p className="mb-2">We have reviewed your campaign but unfortunately at this stage we are unable to approve it. There are several reasons that we don&apos;t approve campaigns, but if you had to www.chuffed.org/tart, you can read about conditions you&apos;ll need to satisfy to run a campaign with us.</p>
      <p className="mb-2 font-semibold">TEMPLATE REASONS:</p>
      <ul>
        <li className="mb-2">Unfortunately, your campaign did not meet our social cause criteria. For campaigns of this nature you&apos;ll need to use a generalist crowdfunding platform like Indiegogo.</li>
        <li className="mb-2">Unfortunately, we do not accept campaigns promoting any particular religion or atheism. We do however accept campaigns run by religious organisations which are for social purposes only and which do not involve any elements of religious education.</li>
        <li className="mb-2">Unfortunately, for personal campaigns of this nature you&apos;ll need to use a generalist crowdfunding platform like Gofundme</li>
      </ul>
      <textarea ref={reasonRef} className="form-control mb-4 w-full" name="reason" col="10" rows="10" placeholder="Reason goes here..." />
      <p className="mb-4 w-full">The decision does not at all reflect the worthiness of your cause or what you&apos;re trying to achieve. It simply reflects that your campaign doesn&apos;t fit into the criteria that we accept. You are free to start a new campaign at any point in time.</p>
    </div>
  )
  : (
    <div>
      <p className="mb-2">We reviewed it, but unfortunately there were some changes you&apos;ll need to make before we can approve it.</p>
      <p className="mb-2 font-semibold">TEMPLATE REASONS:</p>
      <ul>
        <li className="mb-2">You will need to add more details about yourself and break down exactly what funds raised will be spent on</li>
        <li className="mb-2">For campaigns of this nature, we now need to perform an extra step of due diligence. Could you please email support@chuffed.org with links to your Facebook / LinkedIn accounts and a copy of your photo identification (driver&apos;s licence or passport)</li>
        <li className="mb-2">You will need to add more detail to the the main description part of your campaign, including removing any template content. Most successful campaigns contain 300-500 words for this part.</li>
      </ul>
      <p className="mb-2">More specifically:</p>
      <textarea ref={reasonRef} className="form-control mb-4 w-full" name="reason" col="10" rows="10" placeholder="Reason goes here..." />
      <p className="mb-4 w-full">Once you&apos;ve made these changes just re-submit your campaign for approval and we&apos;ll take a look.</p>
    </div>
  ));

export default generateCampaignReviewEmailContent;
