import { React } from "react";
import ModalForm from "@containers/ModalForm";
import RegularGiving from "@containers/RegularGiving";

export const mapActionToContent = (context) => ({
  "user:delete": (<p>Are you sure you want to delete user?</p>),
  "user:login-as": (<p>Are you sure? You will need to log out to resume admin!</p>),
  "user:regular-giving:list": <RegularGiving type="regularGivingList" item={context} />,
  "user:regular-giving:cancel": <RegularGiving type="regularGivingCancel" item={context} />,
});

export const requiresContext = (context) => ({
  "user:update-email": context.email,
});

export const requiresCustomContent = (
  map, actionKey, actionContext,
) => Object.keys(map(actionContext)).includes(actionKey);

export const generateDefaultAdminActions = (actions, actionContext) => actions.map((action) => {
  const isCustomContent = requiresCustomContent(mapActionToContent, action.key, actionContext)
    ? mapActionToContent(actionContext)[action.key] : (
      <ModalForm
        defaultValues={actionContext && requiresContext(actionContext)[action.key]}
        subHeading={action.arguments[0].label}
        fieldsInfo={actions.find((i) => i.key === action.key)}
      />
    );

  return ({
    key: action.key,
    type: action.type || null,
    content: isCustomContent,
    actionLabel: action.arguments[0].label,
  });
});
