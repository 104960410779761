import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import { loader } from "graphql.macro";
import CampaignsComponent from "./components/campaigns";

const APPROVE_CAMPAIGN = loader("./approveCampaign.gql");
const REJECT_CAMPAIGN = loader("./rejectCampaign.gql");
const REVERT_CAMPAIGN = loader("./revertCampaign.gql");

const ApolloWrappedCampaign = () => {
  const [actionedCampaign, setActionedCampaign] = useState();

  const [approveCampaign, {
    error: approveCampaignError,
  }] = useMutation(APPROVE_CAMPAIGN, {
    onCompleted(data) {
      setActionedCampaign(data.approveCampaign.campaign.id);
    },
  });

  const [revertCampaign, {
    error: revertCampaignError,
  }] = useMutation(REVERT_CAMPAIGN, {
    onCompleted(data) {
      setActionedCampaign(data.revertCampaign.campaign.id);
    },
  });

  const [rejectCampaign, {
    error: rejectCampaignError,
  }] = useMutation(REJECT_CAMPAIGN, {
    onCompleted(data) {
      setActionedCampaign(data.rejectCampaign.campaign.id);
    },
  });

  return (
    <CampaignsComponent
      approveCampaign={approveCampaign}
      revertCampaign={revertCampaign}
      rejectCampaign={rejectCampaign}
      errors={{
        approveCampaignError,
        revertCampaignError,
        rejectCampaignError,
      }}
      actionedCampaign={actionedCampaign}
    />
  );
};

export default ApolloWrappedCampaign;
