import { React } from "react";

const CampaignOverviewCard = ({ campaign }) => (
  <div className="w-full max-w-md w-full mx-auto bg-white shadow-md rounded-md px-6 py-4 my-6">
    <div className="sm:flex sm:justify-between">
      <div className="flex items-center">
        <div className="ml-2">
          <h3 className="text-lg text-gray-800 font-medium">{campaign.title}</h3>
          <span className="text-gray-600">{campaign.owner.email}</span>
        </div>
      </div>
    </div>
    <div className="flex justify-between items-center mt-4">
      <div>
        <h4 className="text-gray-600 text-sm">Donations</h4>
        <span className="mt-2 text-xl font-medium text-gray-800">@todo</span>
      </div>
      <div>
        <h4 className="text-gray-600 text-sm">Collected</h4>
        <span className="mt-2 text-xl font-medium text-gray-800">@todo</span>
      </div>
      <div>
        <h4 className="text-gray-600 text-sm">Target</h4>
        <span className="mt-2 text-xl font-medium text-gray-800">{campaign.target.amount}</span>
      </div>
    </div>
  </div>
);

export default CampaignOverviewCard;
