import React from "react";

import ControlledEditor from "./controlledEditor";

const EditorFieldComponent = (props) => {
  const {
    placeholder,
    input: {
      onChange,
      value,
    },
    label,
    disabled,
    id,
    defaultValue,
  } = props;

  return (
    <ControlledEditor
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      label={label}
    />
  );
};

export default EditorFieldComponent;
