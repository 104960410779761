/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";

const styleClasses = {
  primary: "w-full flex p-2 items-center justify-center rounded-lg bg-primary-300 text-white disabled:opacity-50",
  secondary: "w-30 flex p-2 items-center justify-center rounded-lg bg-white disabled:opacity-50",
  tertiary: "w-full flex p-2 items-center justify-center border border-solid border-grey-500 rounded-lg bg-grey-100 text-grey-500 hover:opacity-50",
  link: "disabled:opacity-50 text-left",
};

export const classes = (type, additionalClasses) => (additionalClasses !== undefined ? `${styleClasses[type]} ${additionalClasses}` : styleClasses[type]);

const Button = ({
  onClick,
  disabled,
  type,
  value,
  children,
  classNames = "primary",
  dataTestId = "",
  additionalClasses,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    type={type}
    value={value}
    className={classes(classNames, additionalClasses)}
    data-testid={dataTestId}
  >
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  classNames: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default Button;
