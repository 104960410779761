import FormField from "@components/FormField";
import EditorField from "./editorField";

const emailFormFields = (email, subject, enquiryId, text) => ([
  {
    id: "emailTo",
    name: "emailTo",
    type: "text",
    key: "emailTo",
    component: FormField,
    disabled: false,
    label: "to",
    value: "",
    defaultValue: email,
  },
  {
    id: "emailSubject",
    name: "emailSubject",
    type: "text",
    key: "emailSubject",
    component: FormField,
    disabled: false,
    label: "Subject",
    value: "",
    defaultValue: subject,
  },
  {
    id: "enquiry",
    name: "enquiry",
    type: "hidden",
    key: "enquiry",
    disabled: false,
    component: FormField,
    label: "",
    value: "",
    defaultValue: enquiryId,
  },
  {
    key: "field",
    name: "editorText",
    id: "inputEditorText",
    label: "Content",
    disabled: false,
    value: text,
    component: EditorField,
    type: "",
    defaultValue: text,
  },
]);
export default emailFormFields;
