/* eslint-disable react/state-in-constructor */
import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DatePickerDefault extends Component {
  state = {
    focused: false,
    date: new Date(),
  };

  setDate = (date) => {
    this.setState({
      date,
    });
  };

  render() {
    const {
      meta: {
        touched,
        error,
      },
      input,
      onBlur,
    } = this.props;

    const {
      focused,
      date,
    } = this.state;

    return (
      <>
        <DatePicker
          {...input}
          onChange={(selDate) => { this.setDate(selDate); input.onChange(selDate); }}
          onBlur={(selDate) => (onBlur || input.onBlur(selDate))}
          selected={date}
          focused={focused}
          touched
          value={date}
          className="input border border-gray-400 appearance-none rounded w-full px-3 py-3 pt-5 pb-2 focus focus:border-indigo-600 focus:outline-none active:outline-none active:border-indigo-600"
        />
        { error && touched && <span>{error}</span> }
      </>
    );
  }
}

DatePickerDefault.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

export default DatePickerDefault;
