// information to prefill inputs
export const passInfoFields = [
  "user:update-email",
  "user:regular-giving:cancel",
  "user:regular-giving:list",
  "amplify:initial-email",
  "amplify:not-eligible-email",
  "amplify:follow-up-email",
];

export const nonActionableKeys = [
  "user:regular-giving:list",
];
