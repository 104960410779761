import React from "react";
import Form from "./components/form";

const ModalForm = ({
  fieldsInfo,
  subHeading,
  items = null,
  defaultValues = null,
}) => {
  const fields = fieldsInfo.actions.fields;
  return (
    <Form
      fields={fields}
      fieldsInfo={fieldsInfo}
      subHeading={subHeading}
      items={items}
      defaultValues={defaultValues}
    />
  );
};

export default ModalForm;
