import React from "react";
import { useParams } from "react-router-dom";

import Donation from "@containers/Donation";

export default () => {
  const { donation } = useParams();
  return (
    <Donation id={donation} />
  );
};
