import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import ActionsModal from "@components/ActionsModal";
import Button from "@components/Button";
import DateRange from "@components/DateRange";
import EmailForm from "@containers/EmailForm";
import ErrorDisplay from "@components/ErrorDisplay";
import Input from "@components/Input";
import SuccessDisplay from "@components/SuccessDisplay";
import { LoadingSpinner } from "@components/Icons";
import Select from "@components/Select";
import Table from "@components/Table";

import isEmptyObject from "@helpers/emptyObject";
import { filterAmplifyStatusByKey } from "@helpers/filterAmplifyStatus";
import amplifyEmails from "@helpers/amplifyEmails";
import redirectToCampaignPage from "@helpers/redirectDomains";
import { createArrayOfValues } from "@helpers/numberHelpers";
import { updateDashboardLimit, detectSelectionOfPreviousNext, calculatePageOptionsSize } from "@helpers/tableHelpers";
import PipelineStatuses from "./statuses";

import { fetchConfig as fetchConfigAction } from "../../Home/store/actions";
import {
  updateStatus as updateStatusAction,
  sendAmplifyEmail as sendAmplifyEmailAction,
  fetchStatusesCall as fetchStatusesAction,
  fetchAmplifyCampaigns as fetchAmplifyCampaignsAction,
} from "../store/actions";

import { initialContent, notEligibleContent, followUpContent } from "./amplifyEmails";
import dashboardActions from "./dashboardActions";
import { hasAdsManagerLink } from "./dashboardUtils";

export const Dashboard = ({
  sendAmplifyEmail,
  updateStatus,
  fetchAmplifyCampaigns,
  statuses,
  campaigns,
  isLoading,
  error,
  attemptedCampaignFetch,
  config,
  fetchConfig,
  fetchStatusesCall,
  emailSent,
  campaignsMeta,
}) => {
  const [filteredDates, setFilteredDates] = useState();
  const [filteredStatus, setFilteredStatus] = useState();
  const [prefillInfo, setPrefillInfo] = useState();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageSizeOptions, setPageSizeOptions] = useState([10, 20, 30, 40, 50]);
  const [selectPreviousPage, setSelectPreviousPage] = useState(false);
  const [selectNextPage, setSelectNextPage] = useState(true);
  const [pageRows, setPageRows] = useState(10);
  const [pagesAmount, setPagesAmount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [campaignId, setCampaignId] = useState();

  useEffect(() => {
    if (config && isEmptyObject(config)) {
      fetchConfig();
    }
  }, [fetchConfig, config]);

  useEffect(() => {
    if (statuses && isEmptyObject(statuses)) {
      fetchStatusesCall();
    }
  }, [fetchStatusesCall, statuses]);

  useEffect(() => {
    if (campaigns && campaigns.length <= 0 && !attemptedCampaignFetch) {
      fetchAmplifyCampaigns();
    }
  }, [fetchAmplifyCampaigns, campaigns, attemptedCampaignFetch]);

  useEffect(() => {
    if (campaignsMeta && campaignsMeta.total) {
      setPageSizeOptions(createArrayOfValues(campaignsMeta.total));
      const totalPages = calculatePageOptionsSize(campaignsMeta.total, pageRows);
      if (campaignsMeta.total <= pageRows) {
        setSelectNextPage(false);
      }
      setPagesAmount(totalPages);
    }
  }, [campaignsMeta, setPageSizeOptions, setPagesAmount, pageRows]);

  const updateAdvertisementStatus = (campId, status) => updateStatus(campId, status);

  const sendEmail = (campId, data) => sendAmplifyEmail(campId, data, amplifyEmails);

  const setDates = (dates) => {
    setFilteredDates(dates);
    return !dates.includes(null)
      ? fetchAmplifyCampaigns(limit, offset, filteredStatus, statuses, dates, campaignId)
      : null;
  };

  const filterByStatus = (e) => {
    e.preventDefault();
    const status = e.target.value;
    setFilteredStatus(status);
    fetchAmplifyCampaigns(limit, offset, status, statuses, filteredDates, campaignId);
  };

  const resetFilters = () => {
    setFilteredStatus(null);
    setFilteredDates(null);
    setCampaignId("");
    fetchAmplifyCampaigns();
  };

  // @todo: move these functions from here to a wrapper component
  const visibilityPrevNextButton = (buttonOffset, buttonLimit) => {
    const shouldDisable = detectSelectionOfPreviousNext(
      buttonOffset,
      campaignsMeta.total,
      buttonLimit,
    );
    setSelectPreviousPage(shouldDisable.previous);
    setSelectNextPage(shouldDisable.next);
  };

  const calculateCurrentPageByPage = (type) => {
    if (type === "previous") {
      setCurrentPage(currentPage - 1);
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const fetchByPage = (type) => {
    const pagination = updateDashboardLimit(limit, offset, type, pageRows);
    setLimit(pagination.limit);
    setOffset(pagination.offset);
    visibilityPrevNextButton(pagination.offset, limit);
    calculateCurrentPageByPage(type);
    fetchAmplifyCampaigns(
      pagination.limit, pagination.offset, filteredStatus, statuses, filteredDates, campaignId,
    );
  };

  const calculateCurrentPageByRows = (rows) => {
    setCurrentPage(Math.ceil(offset / rows));
  };

  const fetchPerPage = (rows) => {
    setLimit(rows);
    setPageRows(rows);
    visibilityPrevNextButton(offset, rows);
    calculateCurrentPageByRows(rows);
    fetchAmplifyCampaigns(rows, offset, filteredStatus, statuses, filteredDates, campaignId);
  };

  const updateSelected = (info) => setPrefillInfo(info);

  const setSearchByCampaignId = (e) => {
    const id = e.target.value;
    setCampaignId(id);
  };

  const searchByCampaignId = (e) => {
    e.preventDefault();
    fetchAmplifyCampaigns(limit, offset, filteredStatus, statuses, filteredDates, campaignId);
  };

  const defaultEmails = [
    {
      key: "amplify:initial-email",
      type: "initial",
      content: <EmailForm context={prefillInfo} defaultContent={initialContent(prefillInfo)} />,
      actionLabel: "Send",
    },
    {
      key: "amplify:not-eligible-email",
      type: "not eligible",
      content: <EmailForm context={prefillInfo} defaultContent={notEligibleContent(prefillInfo)} />,
      actionLabel: "Send",
    },
    {
      key: "amplify:follow-up-email",
      type: "follow up",
      content: <EmailForm context={prefillInfo} defaultContent={followUpContent(prefillInfo)} />,
      actionLabel: "Send",
    },
  ];

  const columns = [
    {
      Header: "ID",
      accessor: "campaign.id",
      Cell: ({ row }) => (
        <Button
          classNames="link"
          onClick={() => redirectToCampaignPage(row.original.campaign.id)}
        >
          <span className="text-primary-300 hover:text-primary-100">{row.original.campaign.id}</span>
        </Button>
      ),
    },
    {
      Header: "Chuffed.org Campaign Name",
      accessor: "campaign.title",
      Cell: ({ row }) => (
        <Button
          classNames="link"
          onClick={() => redirectToCampaignPage(row.original.campaign.id)}
        >
          <span className="text-left hover:text-primary-100">{row.original.campaign.title}</span>
        </Button>
      ),
    },
    {
      Header: "Campaign Status",
      accessor: "campaign.status",
    },
    {
      Header: "EOI Created At",
      accessor: "created_at",
      Cell: ({ value }) => (moment(value).utc().format("YYYY-MM-DD")),
    },
    {
      Header: "Pipeline Status",
      accessor: "pipeline",
      Cell: ({ row }) => (
        <PipelineStatuses
          currentStatus={filterAmplifyStatusByKey(statuses, row.original.status)}
          statuses={statuses}
          updateStatus={updateAdvertisementStatus}
          campaignId={row.original.id}
        />
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <ActionsModal
          isDropdown
          currentLabel={filterAmplifyStatusByKey(statuses, row.original.status)}
          prefillInfo={row.original}
          action={sendEmail}
          id={row.original.campaign.id}
          defaultContent={defaultEmails}
          actionsConfig={dashboardActions({
            values: {
              adsManagerLink: hasAdsManagerLink(row.original),
            },
          })}
          selected={updateSelected}
        />
      ),
    },
  ];

  return (
    <>
      {
        (error || emailSent)
        && (
        <div className="max-w-max pr-2 mb-2">
          { error ? <ErrorDisplay error={error} /> : <SuccessDisplay message="Successfully sent email" /> }
        </div>
        )
      }

      {
        !isEmptyObject(campaigns) && !isEmptyObject(statuses)
        && (
        <div className="mb-4">
          <div>
            <Select
              value={filteredStatus || ""}
              onChange={(e) => filterByStatus(e)}
              width="medium"
            >
              {
                statuses.data.map((status) => (
                  <option
                    key={status.id}
                    value={status.label || ""}
                    className="rounded-t bg-grey-100 hover:bg-grey-300 py-2 px-4 block whitespace-no-wrap"
                  >
                    {status.label}
                  </option>
                ))
              }
            </Select>

            <DateRange setDates={setDates} />

            <form className="search-invoice flex mb-4 mt-2 w-2/4">
              <Input
                value={campaignId}
                onChange={setSearchByCampaignId}
                type="text"
                placeholder="Campaign ID"
                dataTestId="search-amplify-campaign-id"
                size="medium"
              />
              <div className="w-60">
                <Button
                  dataTestId="amplify-search-id-button"
                  onClick={(e) => searchByCampaignId(e)}
                  type="submit"
                  value="search-by-id"
                  additionalClasses="h-full ml-1"
                >
                  Search By Campaign ID
                </Button>
              </div>
            </form>
          </div>
          <div className="mt-2">
            <Button classNames="link" onClick={() => resetFilters()}>
              <span className="hover:opacity-50">Reset Filters</span>
            </Button>
          </div>
        </div>
        )
      }

      {
        isLoading && !attemptedCampaignFetch
          ? (
            <div className="flex flex-center w-full justify-center">
              <LoadingSpinner />
            </div>
          )
          : (
            <div data-testid="amplify-dashboard">
              {
            !isEmptyObject(campaigns) && !isEmptyObject(statuses)
              ? (
                <Table
                  columns={columns}
                  data={campaigns}
                  totalPage={campaignsMeta.total}
                  pageSizeOptions={pageSizeOptions}
                  selectPreviousPage={selectPreviousPage}
                  selectNextPage={selectNextPage}
                  fetch={fetchByPage}
                  fetchPerPage={fetchPerPage}
                  currentPageSize={pageRows}
                  pagesAmount={pagesAmount}
                  currentPage={currentPage}
                />
              )
              : <p className="mb-4">No amplify campaigns</p>
          }
            </div>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  attemptedCampaignFetch: state.amplifyDashboard.amplifyDashboardReducer.attemptedCampaignFetch,
  campaigns: state.amplifyDashboard.amplifyDashboardReducer.campaigns,
  emailSent: state.amplifyDashboard.amplifyDashboardReducer.emailSent,
  campaignsMeta: state.amplifyDashboard.amplifyDashboardReducer.campaignsMeta,
  error: state.amplifyDashboard.amplifyDashboardReducer.error,
  isLoading: state.amplifyDashboard.amplifyDashboardReducer.isLoading,
  statuses: state.amplifyDashboard.amplifyDashboardReducer.statuses,
  config: state.dashboard.homeReducer.config,
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(fetchConfigAction()),
  fetchStatusesCall: () => dispatch(fetchStatusesAction()),
  fetchAmplifyCampaigns: (
    limit, offset, val, statuses, dates, campaignId,
  ) => dispatch(fetchAmplifyCampaignsAction(limit, offset, val, statuses, dates, campaignId)),
  updateStatus: (campaignId, status) => dispatch(updateStatusAction(campaignId, status)),
  sendAmplifyEmail: (
    campaignId, data, emails,
  ) => dispatch(sendAmplifyEmailAction(campaignId, data, emails)),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const DashboardComponent = connectToStore(Dashboard);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
