import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import { campaignCreation, countries, adAccounts } from "@fixtures/campaignShell/campaignShell";

import Button from "@components/Button";
import Dropdown from "@components/Dropdown";
import FormField from "@components/FormField";
import MultiSelect from "@components/MultiSelect";
import ToggleInput from "@components/ToggleInput";

import validate from "./campaignShellFormValidation";

export const CampaignShellForm = ({
  id,
  handleSubmit,
  submitting,
  invalid,
  audiences,
  vendorAccounts,
}) => {
  const [multiAudiences, setAudiences] = useState([]);

  return (
    <form data-testid="campaign-shell-form" onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-4" data-testid="campaign-shell-form-campaignId">
        <Field
          name="chuffedCampaignId"
          type="number"
          component={FormField}
          label="Chuffed.org campaign id"
          defaultValue={id}
          value={id}
        />
      </div>
      <div data-testid="campaign-shell-form-countries">
        <Field
          name="country"
          component={Dropdown}
          items={countries}
          label="Country"
          inputName="a country"
          size="full"
        />
      </div>
      <div data-testid="campaign-shell-form-adAccounts">
        <Field
          name="adAccount"
          component={Dropdown}
          items={vendorAccounts || adAccounts}
          label="Ad account"
          inputName="account"
          size="full"
        />
      </div>
      <div className="mb-4" data-testid="campaign-shell-form-audiences">
        <Field
          label="Audiences"
          multi
          name="audiences"
          values={audiences}
          component={MultiSelect}
          updateSelections={setAudiences}
          selectedValues={multiAudiences}
          options={audiences}
        />
      </div>
      <div data-testid="campaign-shell-form-campaignCreation">
        <Field
          name="campaignCreation"
          component={Dropdown}
          items={campaignCreation}
          label="Ad campaign creation"
          inputName="creation"
          size="full"
        />
      </div>
      <div className="mb-4" data-testid="campaign-shell-form-dynamicCreative">
        <Field
          name="dynamicCreative"
          id="dynamic-creative"
          component={ToggleInput}
          label="Dynamic Creative"
        />
      </div>
      <div className="mb-4" data-testid="campaign-shell-form-dailyBudget">
        <Field
          name="dailyBudget"
          type="text"
          component={FormField}
          label="Daily budget"
        />
      </div>
      <div data-testid="campaign-shell-form-submit">
        <Button
          type="submit"
          disabled={invalid || submitting}
        >
          Create
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "campaignShellForm",
  validate,
})(CampaignShellForm);
