import { fetch as router } from "@helpers/router";
import axios from "axios";
import { getWithoutHeaders, postWithHeaders } from "@helpers/axiosHelpers";

export const FETCH_VENDORS = "dashboard/FETCH_VENDORS";
export const fetchVendors = (data) => ({
  type: FETCH_VENDORS,
  data,
});

export const FETCH_VENDORS_SUCCESS = "dashboard/FETCH_VENDORS_SUCCESS";
export const fetchVendorsSuccess = (data) => ({
  type: FETCH_VENDORS_SUCCESS,
  data,
});

export const FETCH_VENDORS_FAILURE = "dashboard/FETCH_VENDORS_FAILURE";
export const fetchVendorsFailure = (error) => ({
  type: FETCH_VENDORS_FAILURE,
  error,
});

export const FETCH_AUDIENCES = "dashboard/FETCH_AUDIENCES";
export const fetchAudiences = (data) => ({
  type: FETCH_AUDIENCES,
  data,
});

export const FETCH_AUDIENCES_SUCCESS = "dashboard/FETCH_AUDIENCES_SUCCESS";
export const fetchAudiencesSuccess = (data) => ({
  type: FETCH_AUDIENCES_SUCCESS,
  data,
});

export const FETCH_AUDIENCES_FAILURE = "dashboard/FETCH_AUDIENCES_FAILURE";
export const fetchAudiencesFailure = (error) => ({
  type: FETCH_AUDIENCES_FAILURE,
  error,
});

export const FETCH_VENDOR_ACCOUNTS = "dashboard/FETCH_VENDOR_ACCOUNTS";
export const fetchVendorAccountsAttempted = () => ({
  type: FETCH_VENDOR_ACCOUNTS,
});

export const FETCH_VENDOR_ACCOUNTS_SUCCESS = "dashboard/FETCH_VENDOR_ACCOUNTS_SUCCESS";
export const fetchVendorAccountsSuccess = (data) => ({
  type: FETCH_VENDOR_ACCOUNTS_SUCCESS,
  data,
});

export const FETCH_VENDOR_ACCOUNTS_FAILURE = "dashboard/FETCH_VENDOR_ACCOUNTS_FAILURE";
export const fetchVendorAccountsFailure = (error) => ({
  type: FETCH_VENDOR_ACCOUNTS_FAILURE,
  error,
});

export const CREATE_CAMPAIGN_SHELL = "dashboard/CREATE_CAMPAIGN_SHELL";
export const createCampaignShell = (data) => ({
  type: CREATE_CAMPAIGN_SHELL,
  data,
});

export const CREATE_CAMPAIGN_SHELL_SUCCESS = "dashboard/CREATE_CAMPAIGN_SHELL_SUCCESS";
export const createCampaignShellSuccess = (data) => ({
  type: CREATE_CAMPAIGN_SHELL_SUCCESS,
  data,
});

export const CREATE_CAMPAIGN_SHELL_FAILURE = "dashboard/CREATE_CAMPAIGN_SHELL_FAILURE";
export const createCampaignShellFailure = (error) => ({
  type: CREATE_CAMPAIGN_SHELL_FAILURE,
  error,
});

export const fetchVendorsCall = () => (dispatch) => {
  const endpoint = router("api.v2.advertising.vendors.index");

  dispatch(fetchVendors());

  const request = new Request(endpoint, {
    method: "GET",
    credentials: "include",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        dispatch(fetchVendorsFailure(response.statusText));
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      dispatch(fetchVendorsSuccess(json));
    })
    .catch((error) => dispatch(fetchVendorsFailure(error)));
};

export const fetchAudiencesCall = () => (dispatch) => {
  const endpoint = router("api.v2.advertising.audiences.index");

  dispatch(fetchAudiences());

  const request = new Request(endpoint, {
    method: "GET",
    credentials: "include",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        dispatch(fetchAudiencesFailure(response.statusText));
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      dispatch(fetchAudiencesSuccess(json.data));
    })
    .catch((error) => dispatch(fetchAudiencesFailure(error)));
};

export const fetchVendorAccounts = () => (dispatch) => {
  dispatch(fetchVendorAccountsAttempted());
  axios(getWithoutHeaders(router("api.v2.advertising.vendor.accounts.index")))
    .then((response) => !response.error && dispatch(fetchVendorAccountsSuccess(response.data.data)))
    .catch((error) => dispatch(fetchVendorAccountsFailure(error)));
};

export const createCampaignShellCall = (campaignId) => (dispatch, getState) => {
  dispatch(createCampaignShell());

  const state = getState();
  const values = state.form.campaignShellForm && state.form.campaignShellForm.values;

  if (!values) {
    dispatch(createCampaignShellFailure("Please provide more information"));
    return;
  }

  if (!campaignId && !values.chuffedCampaignId) {
    dispatch(createCampaignShellFailure("Please provide Chuffed Campaign ID"));
    return;
  }

  const endpoint = `${router("api.v2.advertising.vendor.campaigns.create")}`;

  const audiences = values.audiences.map((i) => i.id);
  const budgetToCents = Number(values.dailyBudget * 100);

  const body = {
    campaign_id: Number(campaignId) || Number(values.chuffedCampaignId),
    advertising_vendor_id: 1,
    vendor_state: {
      countries: [values.country],
      budget: budgetToCents,
      audience: audiences,
      account: Number(values.adAccount),
      creation_audiences: values.campaignCreation,
      is_dynamic_creative: values.dynamicCreative || true,
    },
  };

  axios(postWithHeaders(endpoint, body))
    .then((response) => ((!response.error && (response.data && !response.data.error))
      ? dispatch(createCampaignShellSuccess())
      : dispatch(
        createCampaignShellFailure(response.error || (response.data && response.data.error)),
      )))
    .catch((error) => dispatch(createCampaignShellFailure(error || "Unable to create campaign shell")));
};
