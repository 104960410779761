import React from "react";
import Input from "@components/Input";

const FormField = ({
  input,
  label,
  type,
  meta: {
    touched,
    error,
  },
  value,
  defaultValue,
}) => (
  <div className="flex flex-col" data-testid="form-field">
    <label
      aria-label={label}
      htmlFor={label}
      className="mb-2"
    >
      { label }
    </label>
    <Input
      {...input}
      type={type}
      placeholder={label}
      onBlur={(val) => input.onBlur(val)}
      classNames={touched && error ? "border-red-50" : null}
      size="large"
      value={value}
      defaultValue={defaultValue}
    />
    {
      touched
        && (error
          && (
          <span className="text-red-50 text-sm mt-2">
            {error}
          </span>
          )
        )
    }
  </div>
);

export default FormField;
