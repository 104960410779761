import React from "react";
import InvoiceComponent from "./components/invoice";

export default () => (
  <>
    <p className="mt-3 mb-4">
      <span className="text-grey-500">Dashboard</span>
      {" "}
      / Amplify Invoices
    </p>

    <h1 className="mb-4 text-xl font-bold">Amplify Invoices</h1>
    <InvoiceComponent />
  </>
);
