import { React, useState } from "react";
import { LoadingSpinner } from "@components/Icons";
import Button from "@components/Button";
import ErrorDisplay from "@components/ErrorDisplay";
import Input from "@components/Input";
import SuccessDisplay from "@components/SuccessDisplay";

import { useMutation, useQuery } from "@apollo/client";
import { filterUserActions } from "@helpers/generateEndpointActions";
import { loader } from "graphql.macro";
import objectFromArrayKeys from "../../helpers/objectFromArrayKeys";

const crmListCommands = loader("./listCommands.gql");
const crmExec = loader("./execCommands.gql");

const AdminFurtherActions = () => {
  const [showForm, setShowForm] = useState("");
  const [formState, setFormState] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [hasCompleted, setHasCompleted] = useState("");

  const [runCrm] = useMutation(crmExec, {
    onCompleted() {
      setHasCompleted("Successfully updated");
      setShowForm("");
    },
    onError(error) {
      setErrorMsg(error || "Unable to update");
    },
  });
  const { data, loading } = useQuery(crmListCommands);

  const resetUpdateMessages = () => {
    setHasCompleted("");
    setErrorMsg("");
  };

  const toggleForm = (key) => {
    resetUpdateMessages();
    let showFormKey = key;
    if (key === showForm) {
      showFormKey = "";
    }
    setShowForm(showFormKey);
  };

  const updateFormValue = (crmKey, argumentKey, value) => {
    setFormState({ ...formState, ...{ [crmKey]: { [argumentKey]: value } } });
  };

  const getDefaultActionArguments = (actions, key) => objectFromArrayKeys(
    actions
      .find((act) => act.key === key)
      .arguments
      .map((arg) => arg.id),
    "",
  );

  const submitForm = async (key) => {
    const state = formState;
    const actions = data.crmActions;
    const args = { ...getDefaultActionArguments(actions, key), ...(state[key] || {}) };
    await runCrm({ variables: { input: { id: key, arguments: args } } });

    delete state[key];
    setFormState(state);
    return true;
  };

  return (
    <div className="max-w-md">
      {
        loading
          ? (
            <div className="flex flex-center w-full justify-center">
              <LoadingSpinner />
            </div>
          )
          : (
            <>
              {
                data.crmActions.map((action) => filterUserActions(action)
                  && (
                  <div key={action.key} data-testid="admin-further-actions" className="mb-6 hover:text-color:blue">
                    <Button onClick={() => toggleForm(action.key)} classNames="primary">{action.description}</Button>
                    {showForm === action.key
                    && (
                    <div style={{ padding: "20px" }}>
                      <ul>
                        {
                          action.arguments.map((arg) => (
                            <li key={arg.id} className="flex flex-col">
                              <label className="pb-2">{arg.label}</label>
                              <span className="pb-2">
                                <Input type="text" onChange={(e) => updateFormValue(action.key, arg.id, e.currentTarget.value)} placeholder="Please enter a value" />
                              </span>
                            </li>
                          ))
                        }
                      </ul>
                      <Button onClick={() => submitForm(action.key)}>Submit</Button>
                    </div>
                    )}
                  </div>
                  ))
              }
            </>
          )
      }
      {
        errorMsg && <ErrorDisplay error={errorMsg} />
      }
      {
        hasCompleted && <SuccessDisplay message={hasCompleted} />
      }
    </div>
  );
};

export default AdminFurtherActions;
