const keysWithParams = ["amplify:campaign-shell", "amplify:view-tracking-codes"];
const dataTypeWithParams = ["value"];

const hasParams = (
  dataType,
  key,
  params,
) => keysWithParams.includes(key) && dataTypeWithParams.includes(dataType) && params !== null;

export const normalizeDropdownData = (item, type, campaignId = null) => {
  const dataType = typeof (item);
  switch (dataType) {
    case "string": {
      return item;
    }
    case "object": {
      if (hasParams(type, item.actions.fields[0].key, campaignId)) {
        return `${item.actions.fields[0][type]}/${campaignId}`;
      }

      return item.actions && item.actions.fields[0][type];
    }
    default:
      return item;
  }
};

export const normalizeDropdownActionType = (item) => (typeof (item) === "string" ? false : (item && item.actions && item.actions.type));

export const isExternalLink = (url) => url && url.includes("https://");
