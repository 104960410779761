import React from "react";

const Textarea = ({
  input,
  label,
  meta: {
    touched,
    error,
  },
  value,
}) => (
  <div className="flex flex-col" data-testid="form-field">
    <label
      aria-label={label}
      htmlFor={label}
      className="mb-2"
    >
      { label }
    </label>
    <textarea
      {...input}
      placeholder={label}
      onBlur={(val) => input.onBlur(val)}
      className={`resize rounded border border-grey-400 hover:border-grey-500 border-solid h-12 p-1 block w-full ${touched && error ? "border-red-50" : null}`}
      rows={12}
      cols={60}
    >
      {value}
    </textarea>
    {
      touched
        && (error
          && (
          <span className="text-red-50 text-sm mt-2">
            {error}
          </span>
          )
        )
    }
  </div>
);

export default Textarea;
