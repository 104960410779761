const notEligibleTextContent = (fullName) => `<div>
  <div>Hi ${fullName},</div>

  <div>Thanks for expressing your interest in our ChuffedAmplify product. Unfortunately we wouldn't be able to promote your campaign via Amplfiy Ads at this time. This decision doesn't reflect the worthiness of the campaign you're promoting. We're still in the early stages of Amplify's rollout, so we're only able to promote a limited set of types of campaigns. We're looking to improve this in future.</div>

  <div>With that being said, we'd be more than happy to give you some advice on how to promote your campaign.</div>

  <div>I suggest having a good read through our written <a href="https://chuffed.org/how-it-works-crowdfunding/before-you-start">Crowdfunding Guide</a>, which will give you more information about how to write up and promote your project. You can find it here</div>

  <div>Also, you might be interested in our video Crowdfunding Guide: <a href="https://chuffed.org/academy#videos">Our Crowdfunding 101 Video Series</a> with more information on each of the stages of a crowdfunding campaign</div>

  <div>Apologies we can’t be of any further help in this regard.</div>

  <div>The ChuffedAmplify Team</div>
</div>`;

const initialEmailTextContent = (fullName) => `<div>
  <div>Hi ${fullName},</div>

  <div>Thanks for your interest in Chuffed Amplify.</div>

  <div>Amplify ads give you the opportunity to get guaranteed exposure to Chuffed’s community of over 450K ethical donors, helping you to reach your crowdfunding goal more quickly and easily.</div>

  <div>We kick you off with $200 of free ads which we use to advertise your campaign to the Chuffed community on sites like Google, Facebook and Instagram.</div>

  <div>You keep all the donations brought in as a result of these ads. There are no fees.</div>

  <div>If you’re impressed with the results of your Amplify ads, you can chat to us about scaling your digital marketing campaign beyond the free $200 ad spend. In this case, Chuffed continues to manage your ads for free, simply passing on the cost of the ads as charged by Facebook or Google and striving to secure you at least $3 in donations for every $1 spent on ads.*Would you like us to run a free $200 trial for your campaign?*</div>

  <div>Cheers,
  The ChuffedAmplify Teamamplify@chuffed.org</div>
</div>`;

const followUpTextContent = (fullName) => `Hi ${fullName}, default content`;

export const initialContent = (context) => {
  if (context) {
    return {
      subject: "Your ChuffedAmplify Enquiry",
      text: initialEmailTextContent(context.campaign.user.full_name),
    };
  }
};

export const notEligibleContent = (context) => {
  if (context) {
    return {
      subject: "Your ChuffedAmplify Enquiry",
      text: notEligibleTextContent(context.campaign.user.full_name),
    };
  }
};

export const followUpContent = (context) => {
  if (context) {
    return {
      subject: "Your ChuffedAmplify Enquiry",
      text: followUpTextContent(context.campaign.user.full_name),
    };
  }
};
