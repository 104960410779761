import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Formik, Field } from "formik";

import Button from "@components/Button";
import Dropdown from "@components/Dropdown";
import ErrorDisplay from "@components/ErrorDisplay";
import SuccessDisplay from "@components/SuccessDisplay";
import { LoadingSpinner } from "@components/Icons";

import createDropdownOptions from "../helpers/createDropdownOptions";
import handleValidation from "../helpers/handleValidation";

const getTrackingCodes = loader("../queries/getTrackingCodes.gql");
const getAmplifyAdverts = loader("../queries/getAmplifyAdverts.gql");
const linkTrackingCodeToCampaign = loader("../queries/linkTrackingCodeToCampaign.gql");

const TrackingCodes = () => {
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();
  const campaignId = (location && location.pathname.split("/")[2]) || null;

  const [linkTrackingCode, {
    data: successfullyLinked,
    error: unsuccessfulLinking,
  }] = useMutation(linkTrackingCodeToCampaign, {
    onError(error) {
      setErrorMessage(`${error}`);
    },
  });

  const {
    data: trackingCodesData,
    loading: loadingTrackingCodesData,
    error: errorFetchingTrackingCodes,
  } = useQuery(getTrackingCodes, { variables: { id: campaignId } });
  const {
    data: amplifyAdvertsData,
    loading: loadingAmplifyAdverts,
    error: errorFetchingAmplifyAdverts,
  } = useQuery(getAmplifyAdverts, { variables: { id: campaignId } });

  const linkTrackingCodeToAmplifyVendorCampaign = (values) => {
    linkTrackingCode({
      variables: {
        input: {
          amplifyVendorCampaignId: values.amplifyVendorCampaignId,
          campaignId,
          code: values.trackingCodeId,
        },
      },
    });
  };

  return (
    <>
      {
      loadingTrackingCodesData || loadingAmplifyAdverts
        ? (
          <div data-testid="loading-tracking-codes-data" className="flex flex-center w-full justify-center">
            <LoadingSpinner />
          </div>
        )
        : (
          <main data-testid="tracking-codes-container">
            <Formik
              initialValues={{
                trackingCodeId: "",
                amplifyVendorCampaignId: "",
              }}
              validate={(values) => handleValidation(values)}
              onSubmit={(values) => linkTrackingCodeToAmplifyVendorCampaign(values)}
            >
              {
                ({ handleSubmit, setFieldValue }) => (
                  <form>
                    <section className="mb-4">
                      <h1 className="mb-2">Tracking Codes:</h1>
                      {
                          (trackingCodesData
                            && trackingCodesData.campaign.trackingCodes.length >= 1
                          )
                            ? (
                              <Field name="trackingCodeId">
                                {({ meta }) => (
                                  <Dropdown
                                    inputName="Tracking Code"
                                    onBlur={(e) => setFieldValue("trackingCodeId", e.target.value)}
                                    items={createDropdownOptions(trackingCodesData.campaign.trackingCodes, "trackingCodes")}
                                    meta={meta}
                                  />
                                )}
                              </Field>
                            )
                            : <p className="italic">No tracking codes to display</p>
                        }
                    </section>

                    <section className="mb-4">
                      <h1 className="mb-2">Amplify Vendor Campaigns:</h1>
                      {
                          (amplifyAdvertsData
                            && amplifyAdvertsData.campaign.amplify.adverts.length >= 1)
                            ? (
                              <Field name="amplifyVendorCampaignId">
                                {({ meta }) => (
                                  <Dropdown
                                    inputName="Vendor Campaign ID"
                                    onBlur={(e) => setFieldValue("amplifyVendorCampaignId", e.target.value)}
                                    items={createDropdownOptions(amplifyAdvertsData.campaign.amplify.adverts, "amplifyAdvert")}
                                    meta={meta}
                                  />
                                )}
                              </Field>
                            )
                            : <p className="italic">No amplify ads to display</p>
                        }
                    </section>

                    <Button
                      type="button"
                      onClick={handleSubmit}
                      disabled={
                        trackingCodesData.campaign.trackingCodes.length <= 0
                        || amplifyAdvertsData.campaign.amplify.adverts.length <= 0
                      }
                    >
                      Link Tracking Code To Campaign
                    </Button>
                  </form>
                )
                }
            </Formik>
          </main>
        )
      }

      {
        successfullyLinked && <SuccessDisplay message="Successfully linked" />
      }
      {
        unsuccessfulLinking && <ErrorDisplay error={errorMessage} />
      }
      {
        errorFetchingTrackingCodes && <ErrorDisplay error={`Unable to fetch tracking codes for campaign: ${campaignId}`} />
      }
      {
        errorFetchingAmplifyAdverts && <ErrorDisplay error={`Unable to fetch amplify ads for campaign: ${campaignId}`} />
      }
    </>
  );
};

export default TrackingCodes;
