const handleValidation = (values) => {
  const errors = {};

  if (!values.trackingCodeId) {
    errors.trackingCodeId = "Required";
  }

  if (!values.amplifyVendorCampaignId) {
    errors.amplifyVendorCampaignId = "Required";
  }

  return errors;
};

export default handleValidation;
