import React, { Component } from "react";
import {
  ContentState, convertFromHTML, convertToRaw, EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToMarkdown from "draftjs-to-markdown";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class ControlledEditor extends Component {
  constructor(props) {
    super(props);

    const { onChange, defaultValue } = this.props;
    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(`<p>${defaultValue}</p>`),
        ),
      ),
    };

    const { editorState } = this.state;
    onChange(
      draftToMarkdown(convertToRaw(editorState.getCurrentContent())),
    );
  }

  onEditorStateChange = (editorState) => {
    const {
      onChange,
      value,
    } = this.props;

    const newValue = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));

    if (value !== newValue) {
      onChange(newValue);
    }

    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    const { defaultValue, label } = this.props;

    return (
      <>
        <div className="mb-2">
          <label>{ label }</label>
        </div>
        <Editor
          editorState={editorState}
          wrapperClassName="home-wrapper"
          editorClassName="home-editor"
          toolbarStyle={{ marginBottom: 0, border: "none" }}
          editorStyle={{ backgroundColor: "white", padding: "5px" }}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          defaultValue={defaultValue}
        />
      </>
    );
  }
}

export default ControlledEditor;
