import React from "react";
import PropTypes from "prop-types";

const defaultClasses = "input mr-1 border border-solid border-grey-400 hover:border-grey-500 appearance-none rounded focus focus:border-black focus:outline-none active:outline-none active:border-grey-100";

const classes = {
  xsmall: `${defaultClasses} w-8 px-1 py-1 pt-1 pb-1`,
  small: `${defaultClasses} w-20 px-3 py-3 pt-3 pb-3`,
  medium: `${defaultClasses} w-60 px-4 py-3 pt-3 pb-3`,
  large: `${defaultClasses} w-full px-3 py-3 pt-3 pb-3`,
  error: "input mr-1 appearance-none rounded focus focus:border-red-50 focus:outline-none active:outline-none active:border-grey-100 border border-red-50",
};

const Input = ({
  type,
  value,
  defaultValue,
  onChange,
  onBlur,
  placeholder,
  size = "medium",
  classNames = "",
  dataTestId = "",
}) => (
  <input
    type={type}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    defaultValue={defaultValue}
    placeholder={placeholder}
    className={`${classes[size]} ${classNames}`}
    data-testid={dataTestId}
  />
);

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  classNames: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default Input;
