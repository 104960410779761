import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ErrorDisplay from "@components/ErrorDisplay";
import { LoadingSpinner } from "@components/Icons";

import ModalForm from "@containers/ModalForm";
import userActions from "@pages/Users/components/userActions";

import { regularGivingList as regularGivingListAction } from "./store/actions";

const requiresForm = ["regularGivingCancel"];

const RegularGiving = ({
  item,
  regularGivingList,
  userRegularGiving,
  isLoading,
  type,
  adminActionsError,
  error,
}) => {
  const [beenCalled, setBeenCalled] = useState(false);

  useEffect(() => {
    if (!beenCalled) {
      regularGivingList(item.id);
      setBeenCalled(true);
    }
  }, [regularGivingList, item, beenCalled, setBeenCalled]);

  return (
    <div className="text-left">
      <h2 className="mb-2 font-semibold">Regular Giving:</h2>
      {
        isLoading
        && (
          <div className="flex flex-center w-full justify-center">
            <LoadingSpinner />
          </div>
        )
      }
      {
         !isLoading && (
           userRegularGiving.length <= 0
             ? <p>No regular giving record</p>
             : userRegularGiving.map((info) => (
               <div key={info.id} className="mb-4">
                 <p>
                   Regular Giving ID:
                   {info.id}
                 </p>
                 <p>
                   Active:
                   {info.active ? "True" : "False"}
                 </p>
               </div>
             ))
         )
      }

      {
      requiresForm.includes(type) && userRegularGiving.length > 0
      && (
      <ModalForm
        defaultValues={[]}
        items={userRegularGiving}
        subHeading="Cancel User Regular Giving"
        fieldsInfo={userActions.find((i) => i.key === "user:regular-giving:cancel")}
      />
      )
    }

      {
      (error !== "" || adminActionsError)
      && <div className="max-w-max"><ErrorDisplay error={error !== "" || adminActionsError} /></div>
    }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.regularGiving.isLoading,
  userRegularGiving: state.regularGiving.userRegularGiving,
  error: state.regularGiving.error,
  adminActionsError: state.adminActions.error,
});

const mapDispatchToProps = (dispatch) => ({
  regularGivingList: (userId) => dispatch(regularGivingListAction(userId)),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const RegularGivingComponent = connectToStore(RegularGiving);

export default connect(mapStateToProps, mapDispatchToProps)(RegularGivingComponent);
