/* eslint-disable react/no-array-index-key,react/no-unused-prop-types */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Transition } from "@tailwindui/react";
import { normalizeDropdownData, normalizeDropdownActionType } from "@helpers/normalizeData";
import generateLinkType from "./multiDropdownUtils";

const MultiDropdown = ({
  items,
  label = null,
  onChange,
  dropdownIcon = null,
  campaignId,
  actionItem,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const container = useRef(null);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (container.current && !container.current.contains(event.target)) {
        if (!dropdownOpen) return;
        closeDropdown();
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [dropdownOpen, container]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (!dropdownOpen) return;

      if (event.key === "Escape") {
        closeDropdown();
      }
    };

    document.addEventListener("keyup", handleEscape);
    return () => document.removeEventListener("keyup", handleEscape);
  }, [dropdownOpen]);

  const onChangeSelected = (campId, item) => {
    onChange(campId, item);
    closeDropdown();
  };

  return (
    <div data-testid="multi-dropdown" ref={container} className="multi-dropdown relative inline-block text-left z-1">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        type="button"
        className="inline-flex justify-center w-full p-1 rounded-md border border-grey-300 shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        { label }
        { dropdownIcon }
      </button>

      <Transition
        show={dropdownOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95 z-0"
        enterTo="opacity-100 scale-100 z-10"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100 z-10"
        leaveTo="opacity-0 scale-95 z-0"
      >
        <div
          className="z-10 origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-grey-300 ring-opacity-1 divide-y divide-grey-100"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {
            items.map((item, i) => {
              const linkTo = normalizeDropdownData(item, "value", campaignId);
              const normalLabel = normalizeDropdownData(item, "label");
              const itemInfo = {
                key: i,
                disabled: item.disabled,
              };

              return normalizeDropdownActionType(item) === "Link"
                ? generateLinkType(linkTo, normalLabel, itemInfo)
                : (
                  <button
                    type="button"
                    key={i}
                    onClick={
                  () => (item && onChange
                    ? onChangeSelected(campaignId, item)
                    : actionItem(item))
                }
                    className="block px-4 py-1 text-sm disabled:opacity-50"
                    role="menuitem"
                    disabled={itemInfo.disabled || false}
                  >
                    { normalLabel }
                  </button>
                );
            })
          }
        </div>
      </Transition>
    </div>
  );
};

MultiDropdown.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  dropdownIcon: PropTypes.object,
  campaignId: PropTypes.number,
  actionItem: PropTypes.func,
  action: PropTypes.func,
};

export default MultiDropdown;
