import React from "react";

export const IconChuffed = ({ color, width = "40px" }) => (
  <svg width={width} className="chuffed-logo-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.52 131.78667">
    <path fill={color} d="M.234 22.756v-.095c0-7.268 5.56-13.254 13.352-13.254 4.8 0 7.79 1.615 10.166 4.276l-4.418 4.75c-1.616-1.71-3.23-2.8-5.796-2.8-3.61 0-6.176 3.182-6.176 6.936v.095c0 3.897 2.518 7.03 6.463 7.03 2.42 0 4.085-1.044 5.843-2.706l4.228 4.276c-2.472 2.708-5.322 4.656-10.404 4.656-7.65 0-13.258-5.892-13.258-13.162M24.797.663h7.22v12.83c1.665-2.14 3.8-4.087 7.46-4.087 5.464 0 8.646 3.61 8.646 9.455v16.486h-7.22V21.14c0-3.42-1.616-5.18-4.37-5.18-2.757 0-4.515 1.76-4.515 5.18v14.206h-7.22V.663M50.404 26.367V9.88h7.22v14.207c0 3.42 1.616 5.178 4.373 5.178 2.754 0 4.51-1.757 4.51-5.178V9.88h7.224v25.466H66.51v-3.61c-1.663 2.138-3.8 4.086-7.46 4.086-5.463 0-8.644-3.61-8.644-9.454M78.576 16.056H75.58v-5.938h2.996V8.502c0-2.802.712-4.845 2.042-6.175C81.948.995 83.898.33 86.46.33c2.282 0 3.804.286 5.134.714V7.03c-1.047-.38-2.044-.617-3.277-.617-1.666 0-2.614.854-2.614 2.755v.998h5.844v5.89h-5.75v19.29h-7.22v-19.29M96.08 16.056h-2.994v-5.938h2.993V8.502c0-2.802.713-4.845 2.044-6.175C99.454.995 101.4.33 103.968.33c2.28 0 3.8.286 5.133.714V7.03c-1.046-.38-2.044-.617-3.28-.617-1.662 0-2.613.854-2.613 2.755v.998h5.844v5.89h-5.747v19.29H96.08v-19.29" />
    <path fill={color} d="M125.44 20.618c-.425-3.232-2.327-5.417-5.368-5.417-2.993 0-4.94 2.14-5.51 5.418zm-17.957 2.137v-.095c0-7.27 5.177-13.255 12.588-13.255 8.505 0 12.402 6.604 12.402 13.827 0 .568-.047 1.234-.097 1.9h-17.72c.713 3.277 2.993 4.987 6.224 4.987 2.423 0 4.18-.76 6.176-2.612l4.133 3.656c-2.375 2.947-5.8 4.752-10.405 4.752-7.653 0-13.303-5.368-13.303-13.16M153.47 22.66v-.094c0-4.23-2.804-7.032-6.176-7.032-3.373 0-6.224 2.756-6.224 7.032v.095c0 4.23 2.852 7.032 6.224 7.032 3.372 0 6.177-2.802 6.177-7.03zm-19.62 0v-.094c0-8.505 5.555-13.16 11.59-13.16 3.85 0 6.224 1.756 7.934 3.8V.66h7.223v34.683h-7.223v-3.657c-1.757 2.375-4.18 4.133-7.933 4.133-5.937 0-11.59-4.656-11.59-13.16" />
    <path fill={color} d="M162.118 50.73c0 44.767-36.292 81.057-81.058 81.057C36.292 131.787 0 95.497 0 50.73h162.118" />
  </svg>
);

export const IconArrow = () => (
  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path fill="#000" d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
);

export const IconDropdownArrow = () => (
  <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
  </svg>
);

export const IconEllipsis = () => (
  <svg height="20px" viewBox="0 -192 512 512" width="20px" xmlns="http://www.w3.org/2000/svg">
    <path d="m320 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" />
    <path d="m128 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" />
    <path d="m512 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0" />
  </svg>
);

export const IconTick = () => (
  <svg viewBox="0 0 500 500" height="20px" width="20px">
    <g>
      <path
        id="Path_40"
        fill="#51C51A"
        d="M250,17.5C123.5,18.4,21.7,121.6,22.5,248.2c0,0.6,0,1.2,0,1.8c-1.9,126.5,99.1,230.6,225.6,232.5
      c0.6,0,1.2,0,1.8,0c126.5-0.9,228.3-104.2,227.5-230.7c0-0.6,0-1.2,0-1.8c1.9-126.5-99.1-230.6-225.6-232.5
      C251.2,17.5,250.6,17.5,250,17.5z M338.4,185.3L338.4,185.3L250,330c-2.3,6.4-8.4,10.6-15.2,10.4c-6,0.2-11.8-2.8-15.2-7.8l0,0
      l-45.5-59.4c-3.2-3.6-5-8.2-5.1-12.9c-0.3-9.6,7.2-17.7,16.8-18.1c0.3,0,0.6,0,0.8,0c6-0.2,11.8,2.8,15.2,7.8l0,0l32.9,41.3
      l73.3-124c0-1.4,1-2.5,2.4-2.6c0,0,0.1,0,0.1,0l0,0c2.4-4.8,7.3-7.8,12.6-7.8c9.6-0.1,17.6,7.6,17.7,17.2c0,0.3,0,0.6,0,0.8
      C341,180.2,341,182.8,338.4,185.3L338.4,185.3z"
      />
    </g>
  </svg>
);

export const IconCross = ({ height = "20px", width = "20px" }) => (
  <svg viewBox="0 0 500 500" height={height} width={width}>
    <g>
      <path
        id="Path_55"
        fill="#CB1515"
        d="M250,17.5C123,18.9,21.2,123,22.6,250l0,0C21.2,377,123,481.1,250,482.5l0,0
      C377,481.1,478.8,377,477.4,250c0,0,0,0,0,0l0,0C478.8,123,377,18.9,250,17.5C250,17.5,250,17.5,250,17.5z M152.4,133.5L152.4,133.5
      L152.4,133.5c4.4,0,8.6,1.9,11.6,5.1l86,87.9l86.2-87.8c2.9-3.1,6.9-4.9,11.2-5c9.1-0.1,16.5,7.2,16.6,16.3c0.1,4.6-1.6,9-4.8,12.2
      L273,250l86,87.9c6.5,6.4,6.7,16.8,0.6,23.5c-6,6.5-16.1,6.9-22.6,0.9c-0.1-0.1-0.3-0.3-0.4-0.4l-0.5-0.4l-86-87.9l-86,87.9
      c-6.2,6.3-16.4,6.4-22.7,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-6.3-6.5-6.3-16.9,0-23.4l86-87.9L141,162.1c-6.4-6.4-6.6-16.9-0.3-23.5
      c2.6-2.8,6.2-4.6,10-4.9L152.4,133.5z"
      />
    </g>
  </svg>
);

export const LoadingSpinner = () => (
  <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
  </svg>
);
