import React from "react";
import { reduxForm, Field } from "redux-form";

import Button from "@components/Button";
import Dropdown from "@components/Dropdown";

import FormField from "@components/FormField";

const validate = (values) => {
  const errors = {};

  if (!values.vendorReference) {
    errors.vendorReference = "Required";
  }

  return errors;
};

const CreateLinkForm = ({
  handleSubmit,
  submitting,
  vendors,
}) => (
  <form onSubmit={handleSubmit} data-testid="create-link-form" className="mb-4 mt-2">
    <div data-testid="create-link-vendor-reference" className="mb-2">
      <Field
        name="vendorReference"
        type="text"
        component={FormField}
        label="Vendor Reference"
      />
    </div>
    <div data-testid="create-link-vendors">
      <Field
        name="vendor"
        component={Dropdown}
        items={vendors}
        className="form-control"
        label="Vendors"
      />
    </div>
    <div>
      <Button
        type="submit"
        disabled={submitting}
        dataTestId="create-link-submit"
      >
        Link
      </Button>
    </div>
  </form>
);

export default reduxForm({
  form: "createLinkForm",
  validate,
})(CreateLinkForm);
