import { fetch as router } from "@helpers/router";
import { filterAmplifyStatusByLabel } from "@helpers/filterAmplifyStatus";
import axios from "axios";
import { postWithHeaders } from "@helpers/axiosHelpers";
import { applyIndexFilter } from "@helpers/routerParams";

import moment from "moment";

export const RETRIEVE_CAMPAIGNS = "dashboard/RETRIEVE_CAMPAIGNS";
export function retrieveCampaigns() {
  return {
    type: RETRIEVE_CAMPAIGNS,
  };
}

export const RETRIEVE_CAMPAIGNS_SUCCESS = "dashboard/RETRIEVE_CAMPAIGNS_SUCCESS";
export function retrieveCampaignsSuccess(data, meta) {
  return {
    type: RETRIEVE_CAMPAIGNS_SUCCESS,
    data,
    meta,
  };
}

export const RETRIEVE_CAMPAIGNS_FAILURE = "dashboard/RETRIEVE_CAMPAIGNS_FAILURE";
export function retrieveCampaignsFailure(error) {
  return {
    type: RETRIEVE_CAMPAIGNS_FAILURE,
    error,
  };
}

export const FETCH_STATUSES = "dashboard/FETCH_STATUSES";
export function fetchStatuses() {
  return {
    type: FETCH_STATUSES,
  };
}

export const FETCH_STATUSES_SUCCESS = "dashboard/FETCH_STATUSES_SUCCESS";
export function fetchStatusesSuccess(data) {
  return {
    type: FETCH_STATUSES_SUCCESS,
    data,
  };
}

export const FETCH_STATUSES_FAILURE = "dashboard/FETCH_STATUSES_FAILURE";
export function fetchStatusesFailure(error) {
  return {
    type: FETCH_STATUSES_FAILURE,
    error,
  };
}

export const SEND_EMAIL = "dashboard/SEND_EMAIL";
export function sendEmailAttempt() {
  return {
    type: SEND_EMAIL,
  };
}

export const SEND_EMAIL_SUCCESS = "dashboard/SEND_EMAIL_SUCCESS";
export function sendEmailSuccess(data) {
  return {
    type: SEND_EMAIL_SUCCESS,
    data,
  };
}

export const SEND_EMAIL_FAILURE = "dashboard/SEND_EMAIL_FAILURE";
export function sendEmailFailure(error) {
  return {
    type: SEND_EMAIL_FAILURE,
    error,
  };
}

export const UPDATE_STATUS = "dashboard/UPDATE_STATUS";
export function updateAdvertisingStatus() {
  return {
    type: UPDATE_STATUS,
  };
}

export const UPDATE_STATUS_SUCCESS = "dashboard/UPDATE_STATUS_SUCCESS";
export function updateAdvertisingStatusSuccess(data) {
  return {
    type: UPDATE_STATUS_SUCCESS,
    data,
  };
}

export const UPDATE_STATUS_FAILURE = "dashboard/UPDATE_STATUS_FAILURE";
export function updateAdvertisingStatusFailure(error) {
  return {
    type: UPDATE_STATUS_FAILURE,
    error,
  };
}

export const generateUrl = (
  limit = 10,
  offset = 0,
  status = null,
  statuses = null,
  convertedFromDate = null,
  convertedToDate = null,
  campaignId = null,
) => {
  const statusKey = status !== null && filterAmplifyStatusByLabel(statuses, status);
  const url = `${router("api.v2.advertising.campaigns.index")}?`;

  const pagination = { limit, offset };
  const filters = {
    status: statusKey,
    campaign_id: campaignId,
    from: {
      created_at: convertedFromDate,
    },
    to: {
      created_at: convertedToDate,
    },
  };
  const includes = ["enquiry.responses", "vendor_campaigns.vendor"];
  const sort = { sortBy: ["created_at", "desc"] };
  const params = applyIndexFilter(url, pagination, filters, includes, sort);

  return `${url}${params}`;
};

export const filterHasNoResults = (status, dates, json) => (
  status === null
  || (json && json.length > 0)) && (dates === null || (json && json.length > 0)
);

const convertDate = (date) => moment(date).utc().format("YYYY-MM-DD");

export const fetchAmplifyCampaigns = (
  limit = 10,
  offset = 0,
  status = null,
  statuses = null,
  dates = null,
  campaignId = null,
) => (dispatch) => {
  const convertedFromDate = dates !== null ? convertDate(dates[0]) : null;
  const convertedToDate = dates !== null ? convertDate(dates[1]) : null;

  const endpoint = generateUrl(
    limit,
    offset,
    status,
    statuses,
    convertedFromDate,
    convertedToDate,
    campaignId,
  );

  dispatch(retrieveCampaigns());

  const request = new Request(endpoint, {
    method: "GET",
    credentials: "include",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        dispatch(retrieveCampaignsFailure(response.statusText));
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      if (filterHasNoResults(status, dates, json.data)) {
        dispatch(retrieveCampaignsSuccess(json.data, json.meta));
        dispatch(retrieveCampaignsFailure(""));
      }
      if ((json.data.length <= 0 && status !== null) || (json.data.length <= 0 && dates !== null)) {
        dispatch(
          retrieveCampaignsFailure(
            `No amplify campaigns ${status !== null && `with status of ${status}`} ${dates !== null && `between ${convertedFromDate} to ${convertedToDate}`}`,
          ),
        );
      }
    })
    .catch((error) => dispatch(retrieveCampaignsFailure(error)));
};

export const fetchStatusesCall = () => (dispatch) => {
  const endpoint = router("api.v2.advertising.campaigns.statuses.index");

  dispatch(fetchStatuses());

  const request = new Request(endpoint, {
    method: "GET",
    credentials: "include",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        dispatch(fetchStatusesFailure(response.statusText));
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      dispatch(fetchStatusesSuccess(json));
    })
    .catch((error) => dispatch(fetchStatusesFailure(error)));
};

export const sendAmplifyEmail = (campaignId, data, amplifyEmails) => (dispatch, getState) => {
  const state = getState();

  dispatch(sendEmailAttempt());

  const formValues = state.form.emailForm && state.form.emailForm.values;
  const emailKey = data;

  const filterEmailType = amplifyEmails.find((i) => i.name === emailKey);

  if (!emailKey || !amplifyEmails || !filterEmailType || !formValues) {
    dispatch(sendEmailFailure("Unable to send email, please try again."));
    return;
  }

  const emailType = filterEmailType.name;

  const body = {
    type: emailType,
    subject: formValues.emailSubject,
    content: formValues.editorText,
    to_email: formValues.emailTo,
  };

  const enquiryId = formValues.enquiry;
  const endpoint = router(
    "api.v2.campaigns.manager-enquiries.responses.store",
    { campaign: campaignId, enquiry: enquiryId },
  );

  axios(postWithHeaders(endpoint, body))
    .then((response) => (!response.error ? dispatch(sendEmailSuccess("Success")) : dispatch(sendEmailFailure(response.error))))
    .catch((error) => dispatch(sendEmailFailure(error.message)));
};

export const updateStatus = (campaignId, status) => (dispatch, getState) => {
  const state = getState();
  const token = state && Object.prototype.hasOwnProperty.call(state.dashboard.homeReducer, "config") && state.dashboard.homeReducer.config.data.data.csrf.token;
  const statuses = state.amplifyDashboard.amplifyDashboardReducer.statuses.data;

  if (!statuses || campaignId) {
    dispatch(updateAdvertisingStatusFailure("Unable to update status please try again."));
  }

  const filterStatuses = statuses.filter((i) => i.label === status);
  const statusKey = filterStatuses.length > 0 && filterStatuses[0].key;

  dispatch(updateAdvertisingStatus());

  const body = {
    status: statusKey,
  };

  const endpoint = router("api.v2.advertising.campaigns.update", { id: campaignId });

  const request = new Request(endpoint, {
    method: "PUT",
    credentials: "include",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": token,
    },
    body: JSON.stringify(body),
  });

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        dispatch(updateAdvertisingStatusFailure(response.statusText));
        throw Error(response.statusText);
      }
      dispatch(updateAdvertisingStatusSuccess(response.json()));
      dispatch(fetchAmplifyCampaigns());
      return response;
    })
    .catch((error) => dispatch(updateAdvertisingStatusFailure(error)));
};
