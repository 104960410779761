import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Button from "@components/Button";
import Input from "@components/Input";
import Select from "@components/Select";

// @deprecated
const SimpleTable = ({
  columns,
  data,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 80,
      maxWidth: 400,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect,
    useResizeColumns,
  );

  const getClassName = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? "table__header table__down" : "table__header table__up";
    }
    return "table__header";
  };

  return (
    <div data-testid="table" className="mb-4">
      <div className="table w-full border-solid border border-grey-300 rounded-r-lg rounded-l-lg">
        <MaUTable {...getTableProps()} className="tabled-fixed w-full">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    data-testid="table-cell"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={getClassName(column)}
                  >
                    {column.render("Header")}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()} className="bg-white">
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow data-testid="table-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => <TableCell {...cell.getCellProps()}><span className={cell.column.Header === "ID" ? "text-primary-300 break-all" : "break-all"}>{cell.render("Cell")}</span></TableCell>)}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </div>
      <div className="flex w-full justify-center content-center pt-4 border border-grey-300 rounded-b-lg">
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <div className="flex w-full justify-center content-center">
          <span className="flex items-center">
            Page
            <Input
              type="number"
              value={pageIndex + 1}
              onChange={(e) => {
                const val = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(val);
              }}
              size="small"
              classNames="ml-2"
            />
            <span className="flex pr-4">
              of
              {pageOptions.length}
            </span>
          </span>
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            width="small"
          >
            {[10, 20, 30, 40, 50].map((optionPageSize) => (
              <option key={optionPageSize} value={optionPageSize} className="rounded-t bg-grey-100 hover:bg-grey-300 py-2 px-4 block whitespace-no-wrap">
                {optionPageSize}
                {" "}
                rows
              </option>
            ))}
          </Select>
        </div>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </div>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};

export default SimpleTable;
