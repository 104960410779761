import React from "react";
import PropTypes from "prop-types";

import Select from "../Select";

const Dropdown = ({
  items,
  input = null,
  label = null,
  onBlur = null,
  inputName = "",
  size = "full",
  meta: {
    touched,
    error,
  },
}) => {
  const renderSelectOptions = (item) => (
    <option
      key={item.id}
      value={item.name || item.id}
      data-testid="dropdown-options"
      className="rounded-t bg-grey-100 hover:bg-grey-300 py-2 px-4 block whitespace-no-wrap"
    >
      { item.label || item.id }
    </option>
  );

  return (
    <div
      data-testid="dropdown"
      className="flex flex-col"
    >
      <label
        className="mb-2"
        htmlFor={label}
        aria-label={label}
      >
        { label }
      </label>
      <Select
        {...input}
        width={size}
        onBlur={onBlur || ((value) => input.onBlur(value))}
        classNames={`${touched && error ? "border-red-50 mb-2" : "mb-4"}`}
      >
        <option value="" disabled className="text-grey-400" selected>
          Select
          {" "}
          { inputName }
        </option>
        { items && items.map(renderSelectOptions) }
      </Select>
      {
        touched
          && (error
            && (
            <span className="text-red-50 text-sm mt-0">
              {error}
            </span>
            )
          )
      }
    </div>
  );
};

Dropdown.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  inputName: PropTypes.string,
  size: PropTypes.string,
  meta: PropTypes.object,
};

export default Dropdown;
