/* eslint-disable no-console */
import {
  ApolloLink, ApolloClient, InMemoryCache, HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { fetchAbsolute as router } from "./router";

const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`));
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);

  // this will continue to load the component
  forward(operation);
});

const httpLink = new HttpLink({
  uri: router("graphql.query"),
  credentials: "include",
  headers: {
    accept: "application/json",
    "content-type": "application/json",
  },
});

const link = ApolloLink.from([
  errorLink,
  httpLink,
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export default client;
