import React from "react";
import { IconTick } from "@components/Icons";

const SuccessDisplay = ({
  message,
}) => (
  <div className="campaign-shell-success mb-4 py-2 bg-green-50 border border-solid border-green-100 rounded pr-2">
    <p className="flex items-center">
      <span className="px-2"><IconTick /></span>
      { message }
    </p>
  </div>
);

export default SuccessDisplay;
