import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRange = ({ setDates }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [shouldClose, setShouldClose] = useState(true);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setShouldClose(endDate !== null);
    setDates(dates);
  };

  return (
    <span data-testid="date-range-picker">
      <DatePicker
        className="ml-2 pl-2 rounded border border-grey-400 hover:border-grey-500 border-solid h-12 appearance-none undefined w-60"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        shouldCloseOnSelect={shouldClose}
        inline={false}
        showDisabledMonthNavigation
      />
    </span>
  );
};

export default DateRange;
