import TablePage from "@components/TablePage";
import { React } from "react";
import AdminActions from "../AdminActions";
import AmplifyCampaignShell from "../AmplifyCampaignShell";
import AmplifyDashboard from "../AmplifyDashboard";
import CampaignApprovals from "../CampaignApprovals";
import CampaignVendorLinking from "../CampaignVendorLinking";
import CampaignInvoice from "../CampaignInvoice";
import DonationDetails from "../Donation";
import AmplifyTrackingCodes from "../AmplifyTrackingCodes";
import Users from "../Users";

export const routeMap = [
  {
    path: "/campaignapprovals",
    component: <CampaignApprovals />,
    isExact: false,
  },
  {
    path: "/campaigninvoices",
    component: <CampaignInvoice />,
    isExact: false,
  },
  {
    path: "/users",
    component: <Users />,
    isExact: false,
  },
  {
    path: "/user/:id",
    component: <TablePage />,
    isExact: true,
  },
  {
    path: "/donations/:donation",
    component: <DonationDetails />,
    isExact: true,
  },
  {
    path: "/amplifydashboard",
    component: <AmplifyDashboard />,
    isExact: false,
  },
  {
    path: "/campaignshell",
    component: <AmplifyCampaignShell />,
    isExact: false,
  },
  {
    path: "/campaignshell/:campaignId",
    component: <AmplifyCampaignShell />,
    isExact: true,
  },
  {
    path: "/vendorlinking/:campaign?",
    component: <CampaignVendorLinking />,
    isExact: false,
  },
  {
    path: "/trackingcodes",
    component: <AmplifyTrackingCodes />,
    isExact: false,
  },
  {
    path: "/trackingcodes/:campaignId",
    component: <AmplifyTrackingCodes />,
    isExact: true,
  },
  {
    path: "/adminactions",
    component: <AdminActions />,
    isExact: false,
  },
];

export const actions = [
  {
    to: "/campaignapprovals",
    label: "Review Campaigns",
  },
  {
    to: "/campaigninvoices",
    label: "Amplify Invoices",
  },
  {
    to: "/users",
    label: "Find Users",
  },
  {
    to: "/vendorlinking",
    label: "Amplify Campaign Linking",
  },
  {
    to: "/amplifydashboard",
    label: "Amplify Dashboard",
  },
  {
    to: "/adminactions",
    label: "Admin Actions",
  },
];

export const links = [
  {
    to: "https://chuffed.org/horizon",
    label: "Horizon",
  },
  {
    to: "https://status.chuffed.org",
    label: "Application Status",
  },
  {
    to: "https://login.newrelic.com/",
    label: "New Relic",
  },
];

export const avatar = "https://prod-chuffedcontent.s3.amazonaws.com/images%2Fthumb_7L1itDcajj2NngM5br_Chuffed_Logo_Final.png";
