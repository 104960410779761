import React from "react";
import { reduxForm, Field } from "redux-form";
import FormField from "@components/FormField";
import Textarea from "@components/Textarea";
import Dropdown from "@components/Dropdown";

const Form = ({
  subHeading,
  fields,
  items = null,
  defaultValues,
  fieldsInfo,
}) => {
  const generateFieldComponentByType = (type) => {
    const fieldTypes = {
      dropdown: Dropdown,
      text: FormField,
      textarea: Textarea,
      number: FormField,
    };

    return fieldTypes[type];
  };
  return (
    <form data-testid="dynamic-form" className="flex text-left flex-col">
      <h2 className="text-xl font-bold">{ subHeading }</h2>
      {
        fields && fields.map((field, i) => field.fieldType && (
        <div key={fieldsInfo.key} data-testid={`field-${fieldsInfo.key}`} className="flex">
          <label className="self-center pt-1 pr-2">{field.label}</label>
          <Field
            name={field.key}
            type={field.fieldType}
            component={generateFieldComponentByType(field.fieldType)}
            defaultValue={defaultValues && defaultValues[i]}
            items={field.fieldType === "dropdown" ? (items !== null && items) : null}
          />
        </div>
        ))
      }
    </form>
  );
};

export default reduxForm({
  form: "modalForm",
})(Form);
