const validate = (values) => {
  const errors = {};

  if (!values.chuffedCampaignName) {
    errors.chuffedCampaignName = "Chuffed.org campaign name not valid";
  } else if (typeof (values.chuffedCampaignName) !== "string") {
    errors.chuffedCampaignName = "Must be a string";
  }

  if (!values.country) {
    errors.country = "Country not selected";
  } else if (typeof (values.country) !== "string") {
    errors.country = "Must be a string";
  }

  if (!values.adAccount) {
    errors.adAccount = "Ad account not selected";
  } else if (typeof (values.adAccount) !== "string") {
    errors.adAccount = "Must be a string";
  }

  if (!values.campaignCreation) {
    errors.campaignCreation = "Ad campaign creation not selected";
  } else if (typeof (values.campaignCreation) !== "string") {
    errors.campaignCreation = "Must be a string";
  }

  if (!values.dailyBudget) {
    errors.dailyBudget = "Daily budget not set";
  } else if (Number.isNaN(Number(values.dailyBudget))) {
    errors.dailyBudget = "Must be a number";
  }

  return errors;
};

export default validate;
