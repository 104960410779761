import moment from "moment";
import { fetch as router } from "@helpers/router";

export const RETRIEVE_CAMPAIGN_INVOICE = "dashboard/RETRIEVE_CAMPAIGN_INVOICE";
export const retrieveCampaignInvoice = () => ({
  type: RETRIEVE_CAMPAIGN_INVOICE,
});

export const RETRIEVE_CAMPAIGN_INVOICE_SUCCESS = "dashboard/RETRIEVE_CAMPAIGN_INVOICE_SUCCESS";
export const retrieveCampaignInvoiceSuccess = (data) => ({
  type: RETRIEVE_CAMPAIGN_INVOICE_SUCCESS,
  data,
});

export const RETRIEVE_CAMPAIGN_INVOICE_FAILURE = "dashboard/RETRIEVE_CAMPAIGN_INVOICE_FAILURE";
export const retrieveCampaignInvoiceFailure = (error) => ({
  type: RETRIEVE_CAMPAIGN_INVOICE_FAILURE,
  error,
});

export const CREATE_CAMPAIGN_INVOICE = "dashboard/CREATE_CAMPAIGN_INVOICE";
export const createCampaignInvoice = (data) => ({
  type: CREATE_CAMPAIGN_INVOICE,
  data,
});

export const CREATE_CAMPAIGN_INVOICE_SUCCESS = "dashboard/CREATE_CAMPAIGN_INVOICE_SUCCESS";
export const createCampaignInvoiceSuccess = (data) => ({
  type: CREATE_CAMPAIGN_INVOICE_SUCCESS,
  data,
});

export const CREATE_CAMPAIGN_INVOICE_FAILURE = "dashboard/CREATE_CAMPAIGN_INVOICE_FAILURE";
export const createCampaignInvoiceFailure = (error) => ({
  type: CREATE_CAMPAIGN_INVOICE_FAILURE,
  error,
});

export const fetchCampaignInvoice = (campaignId) => (dispatch) => {
  const endpoint = `${router("web.admin.invoices.index")}?id=${campaignId}`;

  dispatch(retrieveCampaignInvoice());

  const request = new Request(endpoint, {
    method: "GET",
    credentials: "include",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return fetch(request)
    .then((response) => {
      if (!response.ok) {
        dispatch(retrieveCampaignInvoiceFailure(response.statusText));
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      dispatch(retrieveCampaignInvoiceSuccess(json));
    })
    .catch((error) => {
      dispatch(retrieveCampaignInvoiceFailure(error));
    });
};

export const createCampaignInvoiceCall = (campaignId) => (dispatch, getState) => {
  dispatch(createCampaignInvoice());
  const state = getState();

  if (!state.form || !state.form.invoiceForm) {
    dispatch(createCampaignInvoiceFailure("Please fill out form before submitting"));
    return;
  }

  const token = state && Object.prototype.hasOwnProperty.call(state.dashboard.homeReducer, "config") && state.dashboard.homeReducer.config.data.data.csrf.token;
  const values = state.form.invoiceForm.values;

  if (!state.form || !state.form.invoiceForm) {
    dispatch(createCampaignInvoiceFailure("Please fill out form before submitting"));
    return;
  }

  const endpoint = router("web.admin.invoices.index");

  const body = {
    campaign_id: campaignId,
    amount: values.amount,
    description: values.description,
    start_at: moment(values.dateStart).format("YYYY-MM-DD"),
    end_at: moment(values.dateEnd).format("YYYY-MM-DD"),
  };

  const request = new Request(endpoint, {
    method: "POST",
    credentials: "include",
    redirect: "follow",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": token,
    },
  });

  return fetch(request)
    .then((response) => {
      if (response.ok) {
        dispatch(createCampaignInvoiceSuccess(response));
      }
      return response.json();
    })
    .then((json) => {
      if (json.errors) {
        dispatch(createCampaignInvoiceFailure(json.errors));
      }
    })
    .catch((error) => {
      dispatch(createCampaignInvoiceFailure(error));
    });
};
