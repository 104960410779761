import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const ToggleInput = ({
  id,
  input: {
    value,
    onChange,
  },
  label,
}) => (
  <>
    <div className="mb-2">
      <label data-testid="toggle-label">{label}</label>
    </div>
    <div data-testid="toggle-input">
      <Toggle
        id={id}
        defaultChecked
        checked={value}
        onChange={onChange}
      />
    </div>
  </>
);

export default ToggleInput;
