import { React } from "react";

const Header = ({ label, paths = {} }) => {
  const allPaths = { ...{ Dashboard: "/" }, ...paths };
  return (
    <>
      <p className="mt-3 mb-4" data-testid="paths">
        { Object.entries(allPaths).map((route) => (
          <span key={route[0]}>
            <span className="text-grey-500">{route[0]}</span>
            {" "}
            /
            {" "}
          </span>
        )) }
        {label}
      </p>
      <h1 className="mb-4 text-xl font-bold" data-testid="label">{label}</h1>
    </>
  );
};

export default Header;
