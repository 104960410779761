import React from "react";
import UsersComponent from "./components/users";

export default () => (
  <>
    <p className="mt-3 mb-4">
      <span className="text-grey-500">Dashboard</span>
      {" "}
      / Users
    </p>

    <h1 className="mb-4 text-xl font-bold">Users</h1>
    <UsersComponent />
  </>
);
