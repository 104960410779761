import axios from "axios";
import { fetch as router } from "@helpers/router";
import { postWithHeaders } from "@helpers/axiosHelpers";

export const REGULAR_GIVING_LIST = "regularGiving/REGULAR_GIVING_LIST";
export const fetchRegularGivingList = () => ({
  type: REGULAR_GIVING_LIST,
});

export const REGULAR_GIVING_LIST_SUCCESS = "regularGiving/REGULAR_GIVING_LIST_SUCCESS";
export const fetchRegularGivingListSuccess = (data) => ({
  type: REGULAR_GIVING_LIST_SUCCESS,
  data,
});

export const REGULAR_GIVING_LIST_FAILURE = "regularGiving/REGULAR_GIVING_LIST_FAILURE";
export const fetchRegularGivingListFailure = (error) => ({
  type: REGULAR_GIVING_LIST_FAILURE,
  error,
});

export const regularGivingList = (userId) => (dispatch) => {
  dispatch(fetchRegularGivingList());

  const endpoint = router("api.v2.crm-actions.run", { key: "user:regular-giving:list" });
  const user = { user: userId };

  axios(postWithHeaders(endpoint, user))
    .catch((error) => dispatch(fetchRegularGivingListFailure(error)))
    .then((response) => dispatch(fetchRegularGivingListSuccess(response.data)));
};
