import {
  REGULAR_GIVING_LIST,
  REGULAR_GIVING_LIST_SUCCESS,
  REGULAR_GIVING_LIST_FAILURE,
} from "./actions";

const initialState = {
  isLoading: false,
  userRegularGiving: [],
  error: "",
};

const regularGivingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGULAR_GIVING_LIST: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case REGULAR_GIVING_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userRegularGiving: action.data,
      };
    }
    case REGULAR_GIVING_LIST_FAILURE: {
      return {
        ...state,
        isLoading: true,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default regularGivingReducer;
