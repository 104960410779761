import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { IconEllipsis } from "@components/Icons";
import MultiDropdown from "@components/MultiDropdown";
import Modal from "@components/Modal";
import Button from "@components/Button";

import isEmptyObject from "@helpers/emptyObject";
import { passInfoFields, nonActionableKeys } from "@helpers/adminModalHelpers";

const ActionsModal = ({
  id,
  prefillInfo = null,
  action,
  defaultContent,
  actionsConfig,
  selected = null,
  isDropdown,
  currentLabel,
  currentAction,
  endpoints,
}) => {
  const [modalInfo, setModalInfo] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [actionKey, setActionKey] = useState();

  const updateModalContent = (act) => {
    const actionType = defaultContent.find((e) => e.key === act);

    if (!actionType) {
      return;
    }

    if (actionType.content) {
      setModalContent(actionType.content);
    }
    setModalInfo(actionType);
  };

  const updateSelected = () => {
    if (selected) {
      selected(prefillInfo);
    }
  };

  useEffect(() => {
    if (!isEmptyObject(modalContent)) {
      setModalVisibility(true);
    }
  }, [modalContent]);

  const closeActionModal = () => {
    setModalVisibility(false);

    // reset modal
    setModalContent();
    setModalInfo();
    setActionKey();
  };

  const actionItem = (act) => {
    const key = act.key;
    const type = act.actions.type;

    if (passInfoFields.includes(key)) {
      updateSelected();
    }
    if (type === "Modal") {
      updateModalContent(key);
    }

    setActionKey({
      key,
      label: act?.actionLabel || "Enter",
    });
  };

  const submitAction = () => {
    action(id, modalInfo.key, endpoints);
    closeActionModal();
  };

  return (
    <div className="actions-modal" data-testid="actions-modal">
      {
        isDropdown
          ? (
            <MultiDropdown
              dropdownIcon={<IconEllipsis />}
              items={actionsConfig}
              action={() => action(id, modalInfo.key, endpoints)}
              actionItem={actionItem}
              campaignId={id}
            />
          )
          : (
            <Button
              onClick={() => actionItem(actionsConfig.find((i) => i.key === currentAction.key))}
              classNames="primary"
            >
              { currentLabel }
            </Button>
          )
      }

      {
        !isEmptyObject(modalContent)
        && (
        <Modal
          isOpen={modalVisibility}
          contentLabel="Modal"
          ariaHideApp={false}
        >
          { modalContent }
          <div className="mt-4 flex justify-center">
            <span className="w-full md:w-40 mr-2">
              <Button classNames="tertiary" onClick={() => closeActionModal()}>Cancel</Button>
            </span>
            {
              !nonActionableKeys.includes(actionKey.key)
              && (
              <span className="w-full md:w-40">
                <Button onClick={() => submitAction()} type="submit" key="submit">Enter</Button>
              </span>
              )
            }
          </div>
        </Modal>
        )
      }
    </div>
  );
};

ActionsModal.propTypes = {
  id: PropTypes.number,
  prefillInfo: PropTypes.object,
  action: PropTypes.func,
  defaultContent: PropTypes.array.isRequired,
  actionsConfig: PropTypes.array.isRequired,
  selected: PropTypes.func,
};

export default ActionsModal;
