import {
  FETCH_CAMPAIGNS_CALL,
  FETCH_CAMPAIGNS_CALL_SUCCESS,
  FETCH_CAMPAIGNS_CALL_FAILURE,
  FETCH_AMPLIFY_CAMPAIGNS,
  FETCH_AMPLIFY_CAMPAIGNS_SUCCESS,
  FETCH_AMPLIFY_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN_VENDOR_LINKING,
  CREATE_CAMPAIGN_VENDOR_LINKING_SUCCESS,
  CREATE_CAMPAIGN_VENDOR_LINKING_FAILURE,
  DELETE_VENDOR_LINK,
  DELETE_VENDOR_LINK_SUCCESS,
  DELETE_VENDOR_LINK_FAILURE,
} from "./actions";

const initialState = {
  campaigns: {},
  amplifyCampaigns: {},
  hasExistingLinks: [],
  isLoading: false,
  error: false,
  createdCampaignVendorLink: false,
  deletedSuccess: false,
};

const campaignVendorLinkingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGNS_CALL: {
      return {
        ...state,
        campaigns: {},
        createdCampaignVendorLink: false,
        deletedSuccess: false,
        isLoading: true,
        error: "",
      };
    }
    case FETCH_CAMPAIGNS_CALL_SUCCESS: {
      return {
        ...state,
        campaigns: action.data,
        isLoading: false,
        error: "",
      };
    }
    case FETCH_CAMPAIGNS_CALL_FAILURE: {
      return {
        ...state,
        campaigns: {},
        isLoading: false,
        error: `Campaign ID Error: ${action.error.message}`,
      };
    }
    case FETCH_AMPLIFY_CAMPAIGNS: {
      return {
        ...state,
        amplifyCampaigns: {},
        createdCampaignVendorLink: false,
        deletedSuccess: false,
        isLoading: true,
        error: "",
      };
    }
    case FETCH_AMPLIFY_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        amplifyCampaigns: action.data,
        hasExistingLinks: action.hasExistingLinks[0],
        isLoading: false,
        error: "",
      };
    }
    case FETCH_AMPLIFY_CAMPAIGNS_FAILURE: {
      return {
        ...state,
        amplifyCampaigns: {},
        isLoading: false,
        error: `Campaign ID Error: ${action.error.message || action.error}`,
      };
    }
    case CREATE_CAMPAIGN_VENDOR_LINKING: {
      return {
        ...state,
        isLoading: true,
        deletedSuccess: false,
        createdCampaignVendorLink: false,
      };
    }
    case CREATE_CAMPAIGN_VENDOR_LINKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        createdCampaignVendorLink: true,
      };
    }
    case CREATE_CAMPAIGN_VENDOR_LINKING_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        createdCampaignVendorLink: false,
      };
    }
    case DELETE_VENDOR_LINK: {
      return {
        ...state,
        deletedSuccess: false,
        isLoading: true,
      };
    }
    case DELETE_VENDOR_LINK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasExistingLinks: state.hasExistingLinks.filter((i) => i.id !== action.id),
        deletedSuccess: true,
      };
    }
    case DELETE_VENDOR_LINK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default campaignVendorLinkingReducer;
