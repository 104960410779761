import { React } from "react";
import EmailComponentForm from "./components/emailComponentForm";

const EmailForm = ({
  defaultContent,
  context: { campaign, enquiry, hasOwnProperty },
}) => (
  <EmailComponentForm
    initialValues={{
      emailSubject: defaultContent.subject,
      editorText: defaultContent.text,
      emailTo: campaign.user.email,
      enquiry: hasOwnProperty("enquiry") ? enquiry.data.id : "",
    }}
    defaultContent={defaultContent}
    user={campaign.user}
  />
);

export default EmailForm;
