import {
  ADMIN_ACTIONS_CALL,
  ADMIN_ACTIONS_SUCCESS,
  ADMIN_ACTIONS_FAILURE,
  POSTING_CRM_ACTION,
  POSTING_CRM_ACTION_SUCCESS,
  POSTING_CRM_ACTION_FAILURE,
} from "./actions";

const initialState = {
  isLoading: false,
  actions: [],
  error: "",
  postSuccess: false,
};

const adminActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_ACTIONS_CALL: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADMIN_ACTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        actions: action.data,
        error: "",
      };
    }
    case ADMIN_ACTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        actions: [],
      };
    }
    case POSTING_CRM_ACTION: {
      return {
        ...state,
        isLoading: true,
        postSuccess: false,
        error: "",
      };
    }
    case POSTING_CRM_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        postSuccess: true,
      };
    }
    case POSTING_CRM_ACTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        postSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default adminActionsReducer;
