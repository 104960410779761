import React from "react";
import DashboardComponent from "./components/dashboard";

export default () => (
  <>
    <p className="mt-3 mb-4">
      <span className="text-grey-500">Dashboard</span>
      {" "}
      / Amplify Campaigns
    </p>

    <h1 className="mb-4 text-xl font-bold">Amplify Campaigns</h1>
    <DashboardComponent />
  </>
);
