import isEmptyObject from "@helpers/emptyObject";

export const hasVendorCampaign = (data) => !isEmptyObject(data);
export const hasVendorInformation = (data) => data.filter((i) => i.hasOwnProperty("vendor"));
export const firstVendorUrl = (data) => (data.length > 0 ? data[0].vendor.data.url : "");

export const hasAdsManagerLink = (data) => {
  let url = "";
  if (data.hasOwnProperty("vendor_campaigns")) {
    url = hasVendorCampaign(data.vendor_campaigns) && hasVendorInformation(data.vendor_campaigns.data).length > 0 ? firstVendorUrl(data.vendor_campaigns.data) : "";
  }

  return url;
};
