import { fetchUsers } from "@pages/Users/store/actions";

const refreshDataByKey = (key, id) => {
  const mapKeyToAction = [
    {
      name: "user:update-email",
      action: {
        refreshActionName: fetchUsers,
        refreshArgs: id,
      },
    },
    {
      name: "user:regular-giving:cancel",
      action: {
        refreshActionName: fetchUsers,
        refreshArgs: id,
      },
    },
  ];

  const hasAction = mapKeyToAction.find((i) => i.name === key);

  return hasAction && hasAction.action;
};
export default refreshDataByKey;
