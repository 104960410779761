const objectFromArrayKeys = (keys, value) => {
  if (!keys) {
    return {};
  }
  const args = {};
  for (let i = 0; i < keys.length; i++) {
    args[keys[i]] = value;
  }
  return args;
};
export default objectFromArrayKeys;
