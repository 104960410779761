import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ActionsModal from "@components/ActionsModal";
import Button from "@components/Button";
import ErrorDisplay from "@components/ErrorDisplay";
import SuccessDisplay from "@components/SuccessDisplay";
import Input from "@components/Input";
import { LoadingSpinner } from "@components/Icons";
import SimpleTable from "@components/SimpleTable"; // @todo: update to Table when api endpoint is available

import isEmptyObject from "@helpers/emptyObject";

import { postCRMAction as postCRMActionAction } from "@containers/AdminActions/store/actions";
import { generateEndpointActions } from "@helpers/generateEndpointActions";
import { generateDefaultAdminActions } from "@helpers/generateDefaultAdminActions";
import {
  fetchUsers as fetchUsersAction,
  deleteUser as deleteUserAction,
  loginAs as loginAsUserAction,
} from "../store/actions";

import { fetchConfig as fetchConfigAction } from "../../Home/store/actions";

import userActions from "./userActions";

export const Users = ({
  isLoading,
  fetchUsers,
  users,
  fetchConfig,
  config,
  deleteUser,
  loginAs,
  loginSuccess,
  deletedSuccess,
  postCRMAction,
  crmActionsLoading,
  postSuccess,
  crmActionsError,
  loggedInUserID,
  error,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchByEmailValue, setSearchByEmailValue] = useState("");
  const [selected, updateSelected] = useState({});

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (config && isEmptyObject(config)) {
      fetchConfig();
    }
  }, [fetchConfig, config]);

  useEffect(() => {
    if (loginSuccess && loggedInUserID !== null) {
      window.location = `${process.env.REACT_APP_URL}/dashboard`;
    }
  }, [loginSuccess, loggedInUserID]);

  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchByEmailChanges = (e) => {
    setSearchByEmailValue(e.target.value);
  };

  const resetInputField = () => {
    setSearchValue("");
  };

  const searchUserById = (e) => {
    e.preventDefault();
    fetchUsers(searchValue, null);
    resetInputField();
  };

  const searchUserByEmail = (e) => {
    e.preventDefault();
    fetchUsers(null, searchByEmailValue);
    resetInputField();
  };

  const endpoints = {
    deleteUser,
    loginAs,
    postCRMAction,
  };

  const defaultContent = generateDefaultAdminActions(userActions, selected);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      Cell: ({ row }) => (
        <Link
          key={row.original.id}
          to={{
            pathname: `/user/${row.original.id}`,
            state: {
              pageType: "user",
              id: row.original.id,
              user: row.original,
              returnUrl: "/users",
            },
          }}
        >
          {row.original.id}
        </Link>
      ),
    },
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Full Name",
      accessor: "full_name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Actions",
      accessor: "user-actions",
      Cell: ({ row }) => (
        <ActionsModal
          isDropdown
          id={row.original.id}
          prefillInfo={row.original}
          defaultContent={defaultContent}
          action={generateEndpointActions}
          actionsConfig={userActions}
          selected={updateSelected}
          endpoints={endpoints}
        />
      ),
    },
  ];

  return (
    <div data-testid="users">
      {
        (isLoading || crmActionsLoading)
          ? (
            <div className="flex flex-center w-full justify-center">
              <LoadingSpinner />
            </div>
          )
          : (
            <div>
              <form className="search flex flex-col h-full mb-4 md:h-10 md:flex-row justify-between">
                <div className="flex mb-2 md:mb-0">
                  <Input
                    value={searchValue}
                    onChange={handleSearchInputChanges}
                    type="text"
                    dataTestId="user-search-id"
                  />
                  <Button dataTestId="user-search-id-button" onClick={searchUserById} type="submit" value="search-by-id">Search By ID</Button>
                </div>
                <div className="flex">
                  <Input
                    value={searchByEmailValue}
                    onChange={handleSearchByEmailChanges}
                    type="text"
                    dataTestId="user-search-email"
                  />
                  <Button dataTestId="user-search-email-button" onClick={searchUserByEmail} type="submit" value="search-by-email">Search By Email</Button>
                </div>
              </form>

              { (error || crmActionsError) && <ErrorDisplay error={error} /> }
              { deletedSuccess && <SuccessDisplay message="Deleted User Successfully" />}
              { postSuccess && <SuccessDisplay message="Action completed" />}

              { users && users.length > 0 && <SimpleTable columns={columns} data={users} /> }
            </div>
          )
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.usersReducer.users,
  loggedInUserID: state.users.usersReducer.loggedInUserID,
  isLoading: state.users.usersReducer.isLoading,
  loginSuccess: state.users.usersReducer.loginSuccess,
  error: state.users.usersReducer.error,
  deletedSuccess: state.users.usersReducer.deletedSuccess,
  config: state.dashboard.homeReducer.config,
  crmActionsLoading: state.adminActions.isLoading,
  crmActionsError: state.adminActions.error,
  postSuccess: state.adminActions.postSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (id, email) => dispatch(fetchUsersAction(id, email)),
  deleteUser: (id, key) => dispatch(deleteUserAction(id, key)),
  loginAs: (id, key) => dispatch(loginAsUserAction(id, key)),
  postCRMAction: (id, key) => dispatch(postCRMActionAction(id, key)),
  fetchConfig: () => dispatch(fetchConfigAction()),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const UsersComponent = connectToStore(Users);

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
