export const filterUserActions = (action) => !action.key.includes("user:");

const mapTypeToFn = (endpoints) => ({
  "user:delete": endpoints.deleteUser,
  "user:login-as": endpoints.loginAs,
  "user:regular-giving:cancel": endpoints.postCRMAction,
  "user:update-email": endpoints.postCRMAction,
});

export const generateEndpointActions = (userId, key, endpoints) => {
  if (mapTypeToFn(endpoints)[key]) {
    return mapTypeToFn(endpoints)[key](userId, key);
  }
  return endpoints.postCRMAction(userId, key);
};
