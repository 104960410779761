import { React } from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";

import PageHeader from "@components/PageHeader";
import CampaignOverviewCard from "@components/CampaignOverviewCard";

const query = loader("./donation.gql");

const Donation = ({ id }) => {
  const { loading, data, error } = useQuery(query, { variables: { id } });
  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error</p>;
  return (
    <>
      <PageHeader label={`#${data.donation.id}`} paths={{ Donations: "/donations" }} />
      {data.donation.amount.amount}
      <br />
      {data.donation.name}
      <CampaignOverviewCard campaign={data.donation.campaign} />
    </>
  );
};

export default Donation;
