/* eslint-disable react/no-did-update-set-state */
import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import Button from "@components/Button";
import ErrorDisplay from "@components/ErrorDisplay";
import { LoadingSpinner } from "@components/Icons";
import SimpleTable from "@components/SimpleTable"; // @todo: update to Table when api endpoint is available
import Modal from "@components/Modal";

import generateCampaignReviewEmailContent from "@helpers/generateEmails";
import isEmptyObject from "@helpers/emptyObject";
import redirectToCampaignPage from "@helpers/redirectDomains";

import { ExternalLinkIcon } from "@heroicons/react/solid";

import { campaignApprovals as campaignApprovalsAction } from "../store/actions";

import { fetchConfig as fetchConfigAction } from "../../Home/store/actions";

export class Campaigns extends Component {
  constructor(props) {
    super(props);

    this.reasonRef = createRef();

    this.state = {
      campaigns: [],
      modalOpen: false,
      type: "",
      campaignId: "",
    };
  }

  componentDidMount() {
    const {
      campaignApprovals,
      fetchConfig,
      config,
    } = this.props;

    if (campaignApprovals) {
      campaignApprovals();
    }

    if (config && isEmptyObject(config)) {
      fetchConfig();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actionedCampaign,
      campaigns,
    } = this.props;

    if (campaigns !== prevProps.campaigns) {
      this.setState({
        campaigns,
      });
    }

    const campaignId = Number(actionedCampaign);

    if (actionedCampaign && actionedCampaign !== prevProps.actionedCampaign) {
      const { campaigns: filterCampaign } = this.state;
      this.setState({
        campaigns: filterCampaign.filter((i) => i.id !== campaignId),
      });
    }
  }

  campaignAction = (type, campaignId) => {
    this.setState({
      modalOpen: true,
      type,
      campaignId,
    });
  }

  actionReason = () => {
    const {
      type,
      campaignId,
    } = this.state;

    const {
      approveCampaign,
    } = this.props;

    this.runAction(type, approveCampaign, campaignId);

    this.closeActionModal();
  }

  runAction = (type, approveCampaign, campaignId) => {
    if (type === "Approve") {
      approveCampaign({ variables: { id: campaignId } });
    }
    this.actionRejection(type, campaignId);
  };

  actionRejection = (type, campaignId) => {
    const reasonContent = this.reasonRef.current.value;
    const reason = reasonContent || "";

    const {
      rejectCampaign,
      revertCampaign,
    } = this.props;

    if (type === "Revert") {
      revertCampaign(
        {
          variables: {
            input: {
              id: campaignId,
              reason,
            },
          },
        },
      );
      return;
    }
    rejectCampaign(
      {
        variables: {
          input: {
            id: campaignId,
            reason,
          },
        },
      },
    );
  }

  closeActionModal = () => {
    this.setState({
      modalOpen: false,
      type: "",
      campaignId: "",
    });
  }

  render() {
    const {
      actionCampaignError,
      isLoading,
    } = this.props;

    const {
      campaigns,
    } = this.state;

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row }) => (
          <Button
            classNames="link"
            onClick={() => redirectToCampaignPage(row.original.id)}
          >
            <span className="text-primary-300 hover:text-primary-100">{row.original.id}</span>
            <ExternalLinkIcon className="h-4 w-4 text-blue-500" />
          </Button>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
        width: 400,
      },
      {
        Header: "User Name",
        accessor: "user_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Stripe",
        accessor: "stripe_account_info",
        Cell: ({ row }) => (
          <a target="_blank" rel="noreferrer" href={`https://dashboard.stripe.com/connect/accounts/${row.original.stripe_account_info}`}>
            stripe
            <ExternalLinkIcon className="h-4 w-4 text-blue-500" />
          </a>
        ),
      },
      {
        Header: "Approve",
        accessor: "approve",
        Cell: ({ row }) => (<Button classNames="link" onClick={() => this.campaignAction("Approve", row.original.id)}><span className="hover:opacity-50">Approve</span></Button>),
      },
      {
        Header: "Reject",
        accessor: "reject",
        Cell: ({ row }) => (<Button classNames="link" onClick={() => this.campaignAction("Reject", row.original.id)}><span className="hover:opacity-50">Reject</span></Button>),
      },
      {
        Header: "Revert",
        accessor: "revert",
        Cell: ({ row }) => (<Button classNames="link" onClick={() => this.campaignAction("Revert", row.original.id)}><span className="hover:opacity-50">Revert</span></Button>),
      },
    ];

    const { modalOpen, type, campaignId } = this.state;
    return (
      <>
        {
          isLoading
            ? (
              <div className="flex flex-center w-full justify-center">
                <LoadingSpinner />
              </div>
            )
            : (
              <div>
                <div data-testid="campaigns">
                  {
                actionCampaignError && <ErrorDisplay error={actionCampaignError} />
              }

                  {
                !isEmptyObject(campaigns)
                  ? <SimpleTable columns={columns} data={campaigns} />
                  : <p>No campaigns to review</p>
              }
                </div>

                <Modal
                  isOpen={modalOpen}
                  contentLabel="Embed Label Modal"
                  ariaHideApp={false}
                >
                  <div className="flex flex-col items-center text-left">
                    <h3 className="mb-4 font-semibold">{`${type} campaign: ${campaignId}`}</h3>
                    {
                  type !== "Approve" && (
                  <span>
                    <h1 className="mb-4 w-full font-semibold">
                      Do you want to add a reason
                      { type }
                      ing?
                    </h1>

                    <p className="mb-4 w-full">Hi [first name],</p>

                    <p>Thanks for submitting your campaign to us for approval.</p>

                    { generateCampaignReviewEmailContent(this.reasonRef, type) }

                    <p>Cheers,</p>
                    <p>The Chuffed.org Team</p>
                  </span>
                  )
}

                    <div className="flex justify-center">
                      <span className="pr-2">
                        <Button classNames="tertiary" onClick={() => this.closeActionModal()}>Cancel</Button>
                      </span>
                      <span>
                        <Button onClick={() => this.actionReason()}>Continue</Button>
                      </span>
                    </div>
                  </div>
                </Modal>
              </div>
            )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.campaignApprovals.campaignApprovalsReducer.campaigns,
  isLoading: state.campaignApprovals.campaignApprovalsReducer.isLoading,
  config: state.dashboard.homeReducer.config,
});

const mapDispatchToProps = (dispatch) => ({
  campaignApprovals: () => dispatch(campaignApprovalsAction()),
  fetchConfig: () => dispatch(fetchConfigAction()),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const CampaignsComponent = connectToStore(Campaigns);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsComponent);
