import React from "react";

import isEmptyObject from "@helpers/emptyObject";

import { IconDropdownArrow } from "@components/Icons";
import MultiDropdown from "@components/MultiDropdown";

const PipelineStatuses = ({
  updateStatus,
  currentStatus = "Expression of interest",
  campaignId,
  statuses,
}) => {
  const dropdownLinks = !isEmptyObject(statuses) ? statuses.data.map((i) => i.label) : null;
  return (
    dropdownLinks
      ? (
        <div data-testid="pipeline-statuses" className="pipeline-statuses">
          <MultiDropdown
            campaignId={campaignId}
            label={currentStatus}
            dropdownIcon={<IconDropdownArrow />}
            items={dropdownLinks}
            onChange={updateStatus}
          />
        </div>
      )
      : <p>Unable to display statuses</p>
  );
};

export default PipelineStatuses;
