import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const multiSelectStyle = {
  control: (base) => ({
    ...base,
    border: "1px solid red",
    "&:hover": {
      borderColor: "red",
    },
  }),
};

// default will be the values passed from endpoint
const MultiSelect = ({
  updateSelections,
  defaultValue,
  options,
  input,
  label,
  meta: {
    touched,
    error,
  },
  selectedValues,
}) => (
  <div className="flex flex-col" data-testid="multi-select">
    <label data-testid="multi-select-label" className="mb-2" htmlFor={label}>{label}</label>
    <Select
      {...input}
      defaultValue={defaultValue}
      isMulti
      name="audiences"
      options={options}
      classNamePrefix="select"
      styles={touched && error && multiSelectStyle}
      onChange={(value) => updateSelections(value)}
      onBlur={() => input.onBlur(selectedValues)}
      value={selectedValues}
      placeholder="Select audiences"
    />
    { touched && (error && <span className="text-red-50 text-sm mt-2">{error}</span>) }
  </div>
);

MultiSelect.propTypes = {
  updateSelections: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  options: PropTypes.object,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export default MultiSelect;
