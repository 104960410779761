export default [
  {
    key: "user:delete",
    arguments: [{}],
    actions: {
      type: "Modal",
      fields: [
        {
          key: null,
          fieldType: null,
          value: null,
          label: "Delete User",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "user:unsubscribe",
    description: "Unsubscribe the users from future mail-outs",
    arguments: [
      {
        key: "emails",
        label: "Emails",
      },
    ],
    actions: {
      type: "Modal",
      fields: [
        {
          key: "emails",
          fieldType: "text",
          value: null,
          label: "Unsubscribe Emails",
        },
      ],
    },
    disabled: false,
  },
];
