import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { connect } from "react-redux";

import Button from "@components/Button";
import { IconChuffed } from "@components/Icons";

import {
  fetchAdminActions as fetchAdminActionsAction,
} from "@containers/AdminActions/store/actions";
import {
  fetchAuth as fetchAuthAction,
  fetchConfig as fetchConfigAction,
} from "./store/actions";

import {
  routeMap,
  actions,
  links,
  avatar,
} from "./navLinks";

const Home = ({
  fetchAuth,
  fetchAdminActions,
  fetchConfig,
  auth,
  config,
}) => {
  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [mobileNavDisplay, setMobileNavDisplay] = useState(false);

  useEffect(() => {
    fetchAuth();

    if (auth) {
      setIsAdmin(true);
    } else {
      setError(error);
    }
  }, [fetchAuth, auth, error]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig, config]);

  useEffect(() => {
    fetchAdminActions();
  }, [fetchAdminActions]);

  const toggleNav = (setNav) => {
    setMobileNavDisplay(setNav);
  };

  const nav = (title, navLinks) => {
    const items = navLinks.map((link) => {
      const linkComponent = link.to.indexOf("/") === 0
        ? <Link key={link.to} className="px-4" to={link.to}>{link.label}</Link>
        : <a key={link.to} className="px-4" href={link.to} target="_blank" rel="noreferrer">{link.label}</a>;

      return (
        <li key={link.to} className="py-2 text-sm text-white hover:bg-grey-200 rounded text-sm font-thin">
          {linkComponent}
        </li>
      );
    });

    return (
      <nav className="flex flex-col mt-10 px-1">
        <p className="text-grey-50 uppercase text-xs px-4">{title}</p>
        <ul>
          {items}
        </ul>
      </nav>
    );
  };

  return (
    <Router>
      {
        isAdmin
        && (
        <div className="app-container flex font-sans">
          <div className={`fixed z-30 inset-y-0 left-0 w-60 h-100 min-h-screen transition duration-300 transform bg-primary-300 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0 ${mobileNavDisplay ? "translate-x-0 ease-out" : "-translate-x-full ease-in"}`}>
            <div className="mt-4 mb-8 px-5">
              <IconChuffed color="white" />
            </div>

            <div className="mt-8 px-5 flex">
              <span className="text-white font-thin"><Link to="/">Dashboard</Link></span>
            </div>

            {nav("Admin Actions", actions)}
            {nav("Links", links)}
          </div>

          <div className="flex-1 flex flex-col overflow-hidden z-0 bg-grey-100">
            <header>
              <div className="flex items-center space-x-4 justify-between lg:justify-end mt-4 px-4">
                <div className="lg:hidden cursor-pointer" onClick={() => toggleNav(true)}>
                  <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>

                <div className="flex items-center space-x-2 relative focus:outline-none">
                  <Button classNames="secondary">
                    <h2 className="text-gray-700 dark:text-gray-300 text-sm sm:block pr-2">Admin</h2>
                    <img
                      className="h-9 w-9 rounded-full border-2 border-purple-500 object-cover"
                      src={avatar}
                      alt="Chuffed Logo"
                    />
                  </Button>
                </div>
              </div>
            </header>

            <Switch>
              {
                routeMap.map((route) => (
                  <Route path={route.path} exact={route.isExact} key={route.path}>
                    <div className="bg-grey-100 h-100 min-h-screen mt-4 px-4 mx-20" onClick={() => toggleNav(false)}>
                      { route.component }
                    </div>
                  </Route>
                ))
              }
            </Switch>
          </div>
        </div>
        )
      }

      {
        (!isAdmin || error !== "")
        && (
        <div>
          <p>
            Please log in
            {" "}
            <a href={`${process.env.REACT_APP_URL}/dashboard`}>here</a>
          </p>
          { error !== "" && <p>{ error }</p> }
        </div>
        )
      }
    </Router>
  );
};

const mapStateToProps = (state) => ({
  auth: state.dashboard.homeReducer.authUser,
  authError: state.dashboard.homeReducer.authError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuth: () => dispatch(fetchAuthAction()),
  fetchConfig: () => dispatch(fetchConfigAction()),
  fetchAdminActions: () => dispatch(fetchAdminActionsAction()),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const HomeComponent = connectToStore(Home);

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
