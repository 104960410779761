import { fetch as router } from "@helpers/router";
import vendors from "@fixtures/advertisingVendors/vendors";
import axios from "axios";

import { getWithHeaders, deleteWithHeaders, postWithHeaders } from "@helpers/axiosHelpers";

export const FETCH_CAMPAIGNS_CALL = "dashboard/FETCH_CAMPAIGNS_CALL";
export const fetchCampaignsCall = (data) => ({
  type: FETCH_CAMPAIGNS_CALL,
  data,
});

export const FETCH_CAMPAIGNS_CALL_SUCCESS = "dashboard/FETCH_CAMPAIGNS_CALL_SUCCESS";
export const fetchCampaignsCallSuccess = (data) => ({
  type: FETCH_CAMPAIGNS_CALL_SUCCESS,
  data,
});

export const FETCH_CAMPAIGNS_CALL_FAILURE = "dashboard/FETCH_CAMPAIGNS_CALL_FAILURE";
export const fetchCampaignsCallFailure = (error) => ({
  type: FETCH_CAMPAIGNS_CALL_FAILURE,
  error,
});

export const FETCH_AMPLIFY_CAMPAIGNS = "dashboard/FETCH_AMPLIFY_CAMPAIGNS";
export const fetchAmplifyCampaignsCall = (data) => ({
  type: FETCH_AMPLIFY_CAMPAIGNS,
  data,
});

export const FETCH_AMPLIFY_CAMPAIGNS_SUCCESS = "dashboard/FETCH_AMPLIFY_CAMPAIGNS_SUCCESS";
export const fetchAmplifyCampaignsSuccess = (data, hasExistingLinks = []) => ({
  type: FETCH_AMPLIFY_CAMPAIGNS_SUCCESS,
  data,
  hasExistingLinks,
});

export const FETCH_AMPLIFY_CAMPAIGNS_FAILURE = "dashboard/FETCH_AMPLIFY_CAMPAIGNS_FAILURE";
export const fetchAmplifyCampaignsFailure = (error) => ({
  type: FETCH_AMPLIFY_CAMPAIGNS_FAILURE,
  error,
});

export const CREATE_CAMPAIGN_VENDOR_LINKING = "dashboard/CREATE_CAMPAIGN_VENDOR_LINKING";
export const createCampaignVendorLinkCall = (data) => ({
  type: CREATE_CAMPAIGN_VENDOR_LINKING,
  data,
});

export const CREATE_CAMPAIGN_VENDOR_LINKING_SUCCESS = "dashboard/CREATE_CAMPAIGN_VENDOR_LINKING_SUCCESS";
export const createCampaignVendorLinkSuccess = (data) => ({
  type: CREATE_CAMPAIGN_VENDOR_LINKING_SUCCESS,
  data,
});

export const CREATE_CAMPAIGN_VENDOR_LINKING_FAILURE = "dashboard/CREATE_CAMPAIGN_VENDOR_LINKING_FAILURE";
export const createCampaignVendorLinkFailure = (error) => ({
  type: CREATE_CAMPAIGN_VENDOR_LINKING_FAILURE,
  error,
});

export const DELETE_VENDOR_LINK = "dashboard/DELETE_VENDOR_LINK";
export const deleteVendorLink = () => ({
  type: DELETE_VENDOR_LINK,
});

export const DELETE_VENDOR_LINK_SUCCESS = "dashboard/DELETE_VENDOR_LINK_SUCCESS";
export const deleteVendorLinkSuccess = (id) => ({
  type: DELETE_VENDOR_LINK_SUCCESS,
  id,
});

export const DELETE_VENDOR_LINK_FAILURE = "dashboard/DELETE_VENDOR_LINK_FAILURE";
export const deleteVendorLinkFailure = (error) => ({
  type: DELETE_VENDOR_LINK_FAILURE,
  error,
});

export const fetchAmplifyCampaigns = (campaignId) => (dispatch) => {
  if (!campaignId) {
    fetchAmplifyCampaignsFailure("No campaign id provided");
  }

  const campaignEndpoint = router("web.admin.campaigns.show", { id: campaignId });
  const vendorCampaignsEndpoint = `${router("api.v2.advertising.campaigns.index")}?filters[campaign_id]=${campaignId}`;
  dispatch(fetchAmplifyCampaignsCall());

  Promise.allSettled(
    [axios(getWithHeaders(campaignEndpoint)), axios(getWithHeaders(vendorCampaignsEndpoint))],
  )
    .catch((error) => dispatch(fetchAmplifyCampaignsFailure(error[0])))
    .then((values) => {
      if (values[0].error) {
        dispatch(fetchAmplifyCampaignsFailure(`No campaign with campaign id of ${campaignId}`));
        return;
      }

      if (values[0] && values[0].value && values[1].value) {
        dispatch(fetchAmplifyCampaignsSuccess(
          values[0].value.data,
          values[1].value.data.data.map((val) => (val.hasOwnProperty("vendor_campaigns") ? val.vendor_campaigns.data : [])),
        ));
        return;
      }
      dispatch(fetchAmplifyCampaignsSuccess(values[1]));
    });
};

export const fetchCampaigns = () => (dispatch) => {
  const endpoint = router("api.v2.advertising.vendor.campaigns.index");

  dispatch(fetchCampaignsCall());

  axios(endpoint, { withCredentials: true })
    .catch((error) => dispatch(fetchCampaignsCallFailure(error)))
    .then((response) => dispatch(fetchCampaignsCallSuccess(response.data.data)));
};

export const createCampaignVendorLink = (campaignId) => (dispatch, getState) => {
  dispatch(createCampaignVendorLinkCall());

  const state = getState();
  const values = state.form.createLinkForm.values;
  const endpoint = router("api.v2.advertising.vendor.campaigns.create");

  if (!values || !values.vendor) {
    dispatch(createCampaignVendorLinkFailure("Please provide vendor reference and vendor"));
    return;
  }

  const vendorId = vendors.find((i) => i.name === values.vendor).id;

  const body = {
    campaign_id: campaignId,
    advertising_vendor_id: vendorId,
    vendor_reference: values.vendorReference,
  };

  axios(postWithHeaders(endpoint, body))
    .then((response) => dispatch(createCampaignVendorLinkSuccess(response)))
    .catch((error) => dispatch(createCampaignVendorLinkFailure(error.message)));
};

export const deleteCampaignVendorLink = (advertisingVendorId) => (dispatch) => {
  const endpoint = router("api.v2.advertising.vendor.campaigns.destroy", { advertisingVendorCampaign: advertisingVendorId });

  dispatch(deleteVendorLink());

  axios(deleteWithHeaders(endpoint))
    .catch((error) => dispatch(deleteVendorLinkFailure(error)))
    .then(() => dispatch(deleteVendorLinkSuccess(advertisingVendorId)));
};
