import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  useTable, useSortBy, usePagination, useFlexLayout, useResizeColumns, useRowSelect,
} from "react-table";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Button from "@components/Button";
import Select from "@components/Select";

const Table = ({
  columns,
  data,
  totalPage,
  currentPage,
  pageSizeOptions,
  selectPreviousPage,
  selectNextPage,
  fetch,
  fetchPerPage,
  currentPageSize,
  pagesAmount,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 80,
      maxWidth: 400,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: currentPageSize },
      pageCount: totalPage,
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect,
    useResizeColumns,
  );

  const updatePageSize = (val) => {
    setPageSize(val);
    fetchPerPage(val);
  };

  const getClassName = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? "table__header table__down" : "table__header table__up";
    }
    return "table__header";
  };

  return (
    <div data-testid="table" className="mb-4">
      <div className="table w-full border-solid border border-grey-300 rounded-r-lg rounded-l-lg">
        <MaUTable {...getTableProps()} className="tabled-fixed w-full">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell data-testid="table-cell" className={getClassName(column)}>
                    {column.render("Header")}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()} className="bg-white">
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow data-testid="table-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => <TableCell {...cell.getCellProps()}><span className={cell.column.Header === "ID" ? "text-primary-300 break-all" : "break-all"}>{cell.render("Cell")}</span></TableCell>)}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </div>
      <div className="flex w-full justify-center content-center pt-4 border border-grey-300 rounded-b-lg">
        <Button onClick={() => { fetch("previous"); }} disabled={!selectPreviousPage}>
          Previous
        </Button>
        <div className="flex w-full justify-center content-center">
          <span className="flex items-center">
            <span className="flex pr-4">
              Page
              { currentPage + 1 }
              {" "}
              of
              { pagesAmount }
            </span>
          </span>
          <Select
            value={pageSize}
            onChange={(e) => updatePageSize(Number(e.target.value))}
            width="small"
          >
            {
              pageSizeOptions.map((pageSizeOpt) => (
                <option
                  key={pageSizeOpt}
                  value={pageSizeOpt}
                  className="rounded-t bg-grey-100 hover:bg-grey-300 py-2 px-4 block whitespace-no-wrap"
                >
                  {pageSizeOpt}
                  {" "}
                  rows
                </option>
              ))
}
          </Select>
        </div>
        <Button onClick={() => { fetch("next"); }} disabled={!selectNextPage}>
          Next
        </Button>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};

export default Table;
