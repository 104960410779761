import { combineReducers } from "redux";
import {
  RETRIEVE_USERS,
  RETRIEVE_USERS_FAILURE,
  RETRIEVE_USERS_SUCCESS,
  DELETE_USER_CALL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  LOGIN_AS_CALL,
  LOGIN_AS_SUCCESS,
  LOGIN_AS_FAILURE,
} from "./actions";

const initialState = {
  users: [],
  isLoading: false,
  error: "",
  loginSuccess: false,
  deletedSuccess: false,
  loggedInUserID: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_USERS: {
      return {
        ...state,
        isLoading: true,
        error: "",
        deletedSuccess: false,
        loggedInUserID: null,
      };
    }
    case RETRIEVE_USERS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
        loggedInUserID: null,
      };
    }
    case RETRIEVE_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: action.data,
        loggedInUserID: null,
      };
    }
    case DELETE_USER_CALL: {
      return {
        ...state,
        isLoading: true,
        deletedSuccess: false,
        error: "",
        loggedInUserID: null,
      };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        deletedSuccess: false,
        isLoading: false,
        loggedInUserID: null,
      };
    }
    case DELETE_USER_SUCCESS: {
      state.users.splice(state.users.filter((i) => i.id === action.userId), 1);
      return {
        ...state,
        isLoading: false,
        deletedSuccess: true,
        users: state.users,
        loggedInUserID: null,
      };
    }
    case LOGIN_AS_CALL: {
      return {
        ...state,
        isLoading: true,
        loginSuccess: false,
        error: "",
        deletedSuccess: false,
        loggedInUserID: null,
      };
    }
    case LOGIN_AS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
        deletedSuccess: false,
        loggedInUserID: null,
      };
    }
    case LOGIN_AS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        loginSuccess: true,
        loggedInUserID: action.data,
        deletedSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ usersReducer });
