import axios from "axios";
import { fetch as router } from "@helpers/router";

export const AUTH = "dashboard/AUTH";
export function auth() {
  return {
    type: AUTH,
  };
}

export const AUTH_SUCCESS = "dashboard/AUTH_SUCCESS";
export function authSuccess() {
  return {
    type: AUTH_SUCCESS,
  };
}

export const AUTH_FAILURE = "dashboard/AUTH_FAILURE";
export function authFailure(error) {
  return {
    type: AUTH_FAILURE,
    error,
  };
}

export const FETCHING_CONFIG = "dashboard/FETCHING_CONFIG";
export function fetchingConfig() {
  return {
    type: FETCHING_CONFIG,
  };
}

export const FETCH_CONFIG_SUCCESS = "dashboard/FETCH_CONFIG_SUCCESS";
export function fetchConfigSuccess(data) {
  return {
    type: FETCH_CONFIG_SUCCESS,
    data,
  };
}

export const FETCH_CONFIG_FAILURE = "dashboard/FETCH_CONFIG_FAILURE";
export function fetchConfigFailure(error) {
  return {
    type: FETCH_CONFIG_FAILURE,
    error,
  };
}

export const fetchConfig = () => (dispatch) => {
  const endpoint = router("api.v2.client.config");

  dispatch(fetchingConfig());

  axios(endpoint)
    .catch((error) => {
      if (error.response) {
        dispatch(fetchConfigFailure(error.response.status));
      } else {
        dispatch(fetchConfigFailure(error.message));
      }
    })
    .then((response) => {
      dispatch(fetchConfigSuccess(response));
    });
};

export const fetchAuth = () => (dispatch) => {
  const endpoint = router("api.v2.me.index");

  dispatch(auth());

  axios(endpoint, { withCredentials: true })
    .catch((error) => {
      dispatch(authFailure(error));
    })
    .then((response) => {
    // todo: currently this is the only info we receive
    // so we check all aspects to ensure it is admin
      if (response && response.data.data.id === 1) {
        dispatch(authSuccess(true));
      }
    });
};
