import { combineReducers } from "redux";
import {
  FETCH_AUDIENCES,
  FETCH_AUDIENCES_SUCCESS,
  FETCH_AUDIENCES_FAILURE,
  FETCH_VENDORS,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_FAILURE,
  FETCH_VENDOR_ACCOUNTS,
  FETCH_VENDOR_ACCOUNTS_SUCCESS,
  FETCH_VENDOR_ACCOUNTS_FAILURE,
  CREATE_CAMPAIGN_SHELL,
  CREATE_CAMPAIGN_SHELL_SUCCESS,
  CREATE_CAMPAIGN_SHELL_FAILURE,
} from "./actions";

const initialState = {
  campaignShellCreated: false,
  isLoading: false,
  error: "",
  vendors: [],
  audiences: [],
  vendorAccounts: [],
  fetchVendorsAttempted: false,
  fetchAudiencesAttempted: false,
  fetchVendorAccountsAttempted: false,
};

const campaignShellReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CAMPAIGN_SHELL: {
      return {
        ...state,
        isLoading: true,
        campaignShellCreated: false,
        error: "",
      };
    }
    case CREATE_CAMPAIGN_SHELL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: "",
        campaignShellCreated: true,
      };
    }
    case CREATE_CAMPAIGN_SHELL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        campaignShellCreated: false,
      };
    }
    case FETCH_VENDORS: {
      return {
        ...state,
        isLoading: true,
        error: "",
        fetchVendorsAttempted: true,
      };
    }
    case FETCH_VENDOR_ACCOUNTS: {
      return {
        ...state,
        isLoading: true,
        fetchVendorAccountsAttempted: true,
      };
    }
    case FETCH_VENDORS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: "",
        vendors: action.data,
      };
    }
    case FETCH_VENDORS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case FETCH_VENDOR_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: "",
        vendorAccounts: action.data,
      };
    }
    case FETCH_VENDOR_ACCOUNTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case FETCH_AUDIENCES: {
      return {
        ...state,
        isLoading: true,
        error: "",
        fetchAudiencesAttempted: true,
      };
    }
    case FETCH_AUDIENCES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: "",
        audiences: action.data,
      };
    }
    case FETCH_AUDIENCES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ campaignShellReducer });
