// @todo: to be removed when the crm actions endpoint gets updated
const dashboardActions = ({ values }) => ([
  {
    key: "amplify:initial-email",
    arguments: [{}],
    actions: {
      type: "Modal",
      fields: [
        {
          key: null,
          fieldType: "text",
          value: "initial",
          label: "Send initial email",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "amplify:not-eligible-email",
    arguments: [{}],
    actions: {
      type: "Modal",
      fields: [
        {
          key: null,
          fieldType: "text",
          value: "eligible",
          label: "Send not eligible email",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "amplify:follow-up-email",
    arguments: [{}],
    actions: {
      type: "Modal",
      fields: [
        {
          key: null,
          fieldType: "text",
          value: "followUp",
          label: "Send follow up email",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "amplify:campaign-shell",
    arguments: [{}],
    actions: {
      type: "Link",
      fields: [
        {
          key: "amplify:campaign-shell",
          fieldType: null,
          value: "/campaignshell",
          label: "Create campaign shell",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "amplify:link-existing",
    arguments: [{}],
    actions: {
      type: "Link",
      fields: [
        {
          key: null,
          fieldType: null,
          value: "/vendorlinking",
          label: "Link to existing campaign",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "amplify:view-tracking-codes",
    arguments: [{}],
    actions: {
      type: "Link",
      fields: [
        {
          key: "amplify:view-tracking-codes",
          fieldType: null,
          value: "/trackingcodes",
          label: "Link To Existing Tracking Codes",
        },
      ],
    },
    disabled: false,
  },
  {
    key: "amplify:view-ads-manager",
    arguments: [{}],
    actions: {
      type: "Link",
      fields: [
        {
          key: null,
          fieldType: null,
          value: values.adsManagerLink,
          label: "View in ads manager",
        },
      ],
    },
    disabled: false,
  },
]);
export default dashboardActions;
