import React from "react";
import ApolloWrappedCampaign from "./apolloWrappedCampaign";

export default () => (
  <>
    <p className="mt-3 mb-4">
      <span className="text-grey-500">Dashboard</span>
      {" "}
      / Review Campaigns
    </p>

    <h1 className="mb-4 text-xl font-bold">Review Campaigns</h1>
    <ApolloWrappedCampaign />
  </>
);
