import React from "react";
import PropTypes from "prop-types";

const sizes = {
  small: "w-20",
  medium: "w-60",
  full: "w-full",
};

const Select = ({
  children,
  value,
  onChange,
  classNames,
  width = "full",
  onBlur = null,
}) => (
  <select
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    className={`rounded border border-grey-400 hover:border-grey-500 border-solid h-12 appearance-none ${classNames} ${sizes[width]}`}
    data-testid="select"
  >
    { children }
  </select>
);

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  classNames: PropTypes.string,
  width: PropTypes.string,
  onBlur: PropTypes.func,
};

export default Select;
