import React from "react";

import ReactModal from "react-modal";

const defaultStyles = {
  content: {
    position: "absolute",
    left: "50%",
    top: "50%",
    bottom: "initial",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minWidth: "300px",
    border: "none",
    background: "hsla(100,100%,100%,1)",
    backgroundColor: "#F9FAFB",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "40px 20px",
    textAlign: "center",
    fontFamily: "Sans-serif",
  },
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(51,51,51,.8)",
  },
};

const Modal = (props) => {
  const { customStyle, children } = props;
  const newCustomStyle = customStyle || {};
  const stylesToUse = {
    content: {
      ...defaultStyles.content,
      ...newCustomStyle.content,
    },
    overlay: {
      ...defaultStyles.overlay,
      ...newCustomStyle.overlay,
    },
  };

  return (
    <ReactModal
      {...props}
      style={stylesToUse}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
