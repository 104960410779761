const createDropdownOptions = (amplifyData, type) => {
  const options = [];

  if (type === "amplifyAdvert") {
    amplifyData.forEach((data) => {
      options.push({
        name: data.vendorReference,
        label: data.vendorReference,
        value: data.id,
      });
    });

    return options;
  }

  if (type === "trackingCodes") {
    amplifyData.forEach((data) => {
      options.push({
        name: data.code,
        label: data.code,
        value: data.code,
      });
    });

    return options;
  }

  return options;
};

export default createDropdownOptions;
