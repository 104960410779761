import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import isEmptyObject from "@helpers/emptyObject";

import Button from "@components/Button";
import { LoadingSpinner } from "@components/Icons";
import Input from "@components/Input";
import { fetchConfig as fetchConfigAction } from "../../Home/store/actions";
import {
  fetchCampaignInvoice as fetchCampaignInvoiceAction,
  createCampaignInvoiceCall as createCampaignInvoiceCallAction,
} from "../store/actions";
import CreateInvoice from "./createInvoice";

export const Invoice = ({
  fetchCampaignInvoice,
  invoice,
  isLoading,
  error,
  createdInvoice,
  createCampaignInvoiceCall,
  config,
  fetchConfig,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSearchInputChanges = (e) => {
    setSuccessMessage("");
    setSearchValue(e.target.value);
  };

  const searchByCampaignId = (e) => {
    e.preventDefault();
    fetchCampaignInvoice(searchValue);
  };

  const resetInputField = () => {
    setSearchValue("");
  };

  const createInvoice = (e) => {
    e.preventDefault();
    createCampaignInvoiceCall(searchValue);
    resetInputField();
  };

  useEffect(() => {
    if (config && isEmptyObject(config)) {
      fetchConfig();
    }
  }, [fetchConfig, config]);

  useEffect(() => {
    if (createdInvoice) {
      setSuccessMessage("Created Invoice Successfully");
      resetInputField();
    }
  }, [createdInvoice]);

  return (
    <>
      {
        isLoading
          ? (
            <div className="flex flex-center w-full justify-center">
              <LoadingSpinner />
            </div>
          )
          : (
            <div data-testid="search-invoice">
              <form className="search-invoice flex mb-4 w-2/4">
                <Input
                  value={searchValue}
                  onChange={handleSearchInputChanges}
                  type="text"
                  placeholder="Campaign ID"
                  dataTestId="search-invoice-campaign-id"
                />
                <Button
                  dataTestId="user-search-id-button"
                  onClick={(e) => searchByCampaignId(e)}
                  type="submit"
                  value="search-by-id"
                >
                  Search By Campaign ID
                </Button>
              </form>

              { invoice && invoice.title && (
              <h2 className="mb-2 font-semibold">
                Campaign:
                { invoice.title }
              </h2>
              ) }

              { successMessage === "" && <CreateInvoice handleSubmit={createInvoice} /> }

              {
                <p className="mt-4">
                  { successMessage }
                  {" "}
                </p>
              }

              {
                <div className="mt-4">
                  {
                (error && typeof (error) !== "string")
                  ? Object.keys(error).map((key) => <p className="mb-1">{ error[key][0] }</p>)
                  : <p>{ error }</p>
              }
                </div>
          }
            </div>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.campaignInvoices.campaignInvoicesReducer.invoice,
  isLoading: state.campaignInvoices.campaignInvoicesReducer.isLoading,
  error: state.campaignInvoices.campaignInvoicesReducer.error,
  createdInvoice: state.campaignInvoices.campaignInvoicesReducer.createdInvoice,
  config: state.dashboard.homeReducer.config,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCampaignInvoice: (campaignId) => dispatch(fetchCampaignInvoiceAction(campaignId)),
  createCampaignInvoiceCall: (campaignId) => dispatch(createCampaignInvoiceCallAction(campaignId)),
  fetchConfig: () => dispatch(fetchConfigAction()),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const InvoiceComponent = connectToStore(Invoice);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceComponent);
