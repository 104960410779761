import {
  createStore, combineReducers, compose, applyMiddleware,
} from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as reduxFormReducer } from "redux-form";

import adminActionsReducer from "@containers/AdminActions/store/reducers";
import amplifyDashboardReducer from "@pages/AmplifyDashboard/store/reducers";
import campaignVendorLinkingReducer from "@pages/CampaignVendorLinking/store/reducers";
import campaignInvoicesReducer from "@pages/CampaignInvoice/store/reducers";
import campaignApprovalsReducer from "@pages/CampaignApprovals/store/reducers";
import campaignShellReducer from "@pages/AmplifyCampaignShell/store/reducers";
import homeReducer from "@pages/Home/store/reducers";
import regularGivingReducer from "@containers/RegularGiving/store/reducers";
import usersReducer from "@pages/Users/store/reducers";

const store = createStore(
  combineReducers({
    adminActions: adminActionsReducer,
    amplifyDashboard: amplifyDashboardReducer,
    campaignInvoices: campaignInvoicesReducer,
    campaignApprovals: campaignApprovalsReducer,
    campaignShell: campaignShellReducer,
    dashboard: homeReducer,
    form: reduxFormReducer,
    regularGiving: regularGivingReducer,
    users: usersReducer,
    vendorLinking: campaignVendorLinkingReducer,
  }),
  compose(
    applyMiddleware(thunkMiddleware),
    // redux chrome devtools:
    // https://github.com/zalmoxisus/redux-devtools-extension
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

export default store;
