import { fetch as router } from "@helpers/router";
import axios from "axios";
import { getWithoutHeaders, postWithHeaders } from "@helpers/axiosHelpers";
import refreshDataByKey from "@helpers/adminActionRefresh";

export const ADMIN_ACTIONS_CALL = "admin/ADMIN_ACTIONS_CALL";
export const adminActionsCall = () => ({
  type: ADMIN_ACTIONS_CALL,
});

export const ADMIN_ACTIONS_SUCCESS = "admin/ADMIN_ACTIONS_SUCCESS";
export const adminActionsSuccess = (data) => ({
  type: ADMIN_ACTIONS_SUCCESS,
  data,
});

export const ADMIN_ACTIONS_FAILURE = "admin/ADMIN_ACTIONS_FAILURE";
export const adminActionsFailure = (error) => ({
  type: ADMIN_ACTIONS_FAILURE,
  error,
});

export const POSTING_CRM_ACTION = "admin/POSTING_CRM_ACTION";
export const postingCRMAction = () => ({
  type: POSTING_CRM_ACTION,
});

export const POSTING_CRM_ACTION_SUCCESS = "admin/POSTING_CRM_ACTION_SUCCESS";
export const postingCRMActionSuccess = () => ({
  type: POSTING_CRM_ACTION_SUCCESS,
});

export const POSTING_CRM_ACTION_FAILURE = "admin/POSTING_CRM_ACTION_FAILURE";
export const postingCRMActionFailure = (error) => ({
  type: POSTING_CRM_ACTION_FAILURE,
  error,
});

export const fetchAdminActions = () => (dispatch) => {
  dispatch(adminActionsCall());

  const endpoint = router("api.v2.crm-actions.index");

  axios(getWithoutHeaders(endpoint))
    .catch((error) => dispatch(adminActionsFailure(error)))
    .then((response) => (
      response.error
        ? dispatch(adminActionsFailure(response.error))
        : dispatch(adminActionsSuccess(response.data.data))));
};

const requiresFormValues = ["user:unsubscribe"];

export const postCRMAction = (id, key) => (dispatch, getState) => {
  const state = getState();
  const args = state.adminActions.actions.find((i) => i.key === key).arguments;
  const formValues = state.form.modalForm ? state.form.modalForm.values : null;

  if (!formValues) {
    dispatch(postingCRMActionFailure("Please add value"));
  }

  dispatch(postingCRMAction());

  const data = {};
  args.forEach((arg, i) => {
    if (requiresFormValues.includes(key)) {
      data[arg.key] = formValues[args[i].key];
      return data[arg.key];
    }
    data[arg.key] = (i === 0 && id) ? id : formValues[args[i].key];
    return data[arg.key];
  });

  const endpoint = router("api.v2.crm-actions.run", { key });

  const refreshAction = refreshDataByKey(key, id);

  axios(postWithHeaders(endpoint, data))
    .catch(() => dispatch(postingCRMActionFailure("Unable to post action")))
    .then((response) => {
      if ((!response.error && (response.data && !response.data.error))) {
        return dispatch(postingCRMActionSuccess(key))
          && (
            refreshAction
            && dispatch(refreshAction.refreshActionName(refreshAction.refreshArgs))
          );
      }

      return dispatch(
        postingCRMActionFailure(response.error || (response.data && response.data.error)),
      );
    });
};
