import { combineReducers } from "redux";
import {
  CAMPAIGN_APPROVALS_LIST,
  CAMPAIGN_APPROVALS_LIST_SUCCESS,
  CAMPAIGN_APPROVALS_LIST_FAILURE,
} from "./actions";

const initialState = {
  campaigns: [],
  isLoading: false,
  error: "",
};

const campaignApprovalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_APPROVALS_LIST: {
      return {
        ...state,
        error: "",
        isLoading: true,
      };
    }
    case CAMPAIGN_APPROVALS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: "",
        campaigns: action.data,
      };
    }
    case CAMPAIGN_APPROVALS_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({ campaignApprovalsReducer });
