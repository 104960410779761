import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorDisplay from "@components/ErrorDisplay";
import SuccessDisplay from "@components/SuccessDisplay";

import isEmptyObject from "@helpers/emptyObject";
import Form from "./campaignShellForm";

import {
  fetchConfig as fetchConfigAction,
} from "../../Home/store/actions";

import {
  createCampaignShellCall as createCampaignShellCallAction,
  fetchVendorsCall as fetchVendorsCallAction,
  fetchAudiencesCall as fetchAudiencesCallAction,
  fetchVendorAccounts,
} from "../store/actions";

export const CampaignShell = ({
  createCampaignShellCall,
  config,
  fetchConfig,
  campaignShellCreated,
  error,
  vendors,
  fetchVendorsAttempted,
  audiences,
  fetchAudiencesAttempted,
  fetchVendorsCall,
  fetchAudiencesCall,
  // eslint-disable-next-line no-shadow
  fetchVendorAccounts,
  fetchVendorAccountsAttempted,
  vendorAccounts,
}) => {
  const location = useLocation();
  const campaignId = (location && location.pathname.split("/")[2]) || null;

  const handleSubmit = (e) => {
    e.preventDefault();
    createCampaignShellCall(campaignId);
  };

  useEffect(() => {
    if (config && isEmptyObject(config)) {
      fetchConfig();
    }
  }, [fetchConfig, config]);

  useEffect(() => {
    if (vendors && vendors.length <= 0 && !fetchVendorsAttempted) {
      fetchVendorsCall();
    }
  }, [fetchVendorsAttempted, vendors, fetchVendorsCall]);

  useEffect(() => {
    if (audiences && audiences.length <= 0 && !fetchAudiencesAttempted) {
      fetchAudiencesCall();
    }
  }, [fetchAudiencesAttempted, audiences, fetchAudiencesCall]);

  useEffect(() => {
    if (vendorAccounts && vendorAccounts.length <= 0 && !fetchVendorAccountsAttempted) {
      fetchVendorAccounts();
    }
  }, [vendorAccounts, fetchVendorAccounts, fetchVendorAccountsAttempted]);

  return (
    <div data-testid="campaign-shell">
      { campaignShellCreated && error === "" && <SuccessDisplay message="Your campaign shell has successfully sent to ad vendor to be created." /> }

      <Form
        audiences={audiences}
        vendors={vendors}
        handleSubmit={handleSubmit}
        vendorAccounts={vendorAccounts}
        id={campaignId}
      />

      { error !== "" && !campaignShellCreated && <ErrorDisplay error={`Unable to create campaign shell: ${error}`} /> }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.campaignShell.campaignShellReducer.isLoading,
  campaignShellCreated: state.campaignShell.campaignShellReducer.campaignShellCreated,
  error: state.campaignShell.campaignShellReducer.error,
  vendors: state.campaignShell.campaignShellReducer.vendors,
  audiences: state.campaignShell.campaignShellReducer.audiences,
  config: state.dashboard.homeReducer.config,
  vendorAccounts: state.campaignShell.campaignShellReducer.vendorAccounts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: () => dispatch(fetchConfigAction()),
  fetchAudiencesCall: () => dispatch(fetchAudiencesCallAction()),
  fetchVendorsCall: () => dispatch(fetchVendorsCallAction()),
  createCampaignShellCall: (campaignId) => dispatch(createCampaignShellCallAction(campaignId)),
  fetchVendorAccounts: () => dispatch(fetchVendorAccounts()),
});

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
const CampaignShellComponent = connectToStore(CampaignShell);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignShellComponent);
